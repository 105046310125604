import React from "react"
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { loginUser } from '../../actions/authActions'
import InputField from '../common/InputField'


class Login extends React.Component {
    constructor() {
        super();

        this.state = {
            email: "",
            password: ""
        }

        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push('/')
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
            this.props.history.push('/')
        }
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit(e) {
        e.preventDefault();

        const user = {
            email: this.state.email,
            password: this.state.password
        }

        this.props.loginUser(user)
    }


    render () {
        const { errors } = this.props

        return (
            <div className="container row">
                <div className="col m3"></div>
                <div className="col s12 m6">
                    <h2>Login</h2>

                    {errors.loginError && (
                        <p className="alert">{errors.loginError}</p>)
                    }
                    
                    <form onSubmit={this.onSubmit}>
                        <InputField
                            name="email"
                            type="email"
                            label="Email"
                            id="loginEmail"
                            onChange={this.onChange}
                            error={errors.email}
                            credentialError={errors.loginError}
                            required="required"
                        />

                        <InputField
                            name="password"
                            type="password"
                            label="Password"
                            id="loginPassword"
                            onChange={this.onChange}
                            error={errors.password}
                            credentialError={errors.loginError}
                            required="required"
                        />

                        <button className="btn btn-primary" type="submit">Sign in</button>
                    </form>
                    <div className="center">
                        <Link to="/879s7d97sdfg78sd6fg6sdfgs8d/register" onClick={this.closeMobileMenu}>Register</Link>
                    </div>
                </div>
            </div>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
})

export default connect(mapStateToProps, { loginUser })(withRouter(Login))