import {
    ADD_FEEDBACK_REQUEST,
    GET_ALL_FEEDBACK_REQUESTS,
    SUCCESS_SUBMIT_FORM,
    LOADING_REQUESTS,
    REMOVE_REPLYED_REQUEST
} from '../actions/types'

const initialState = {
    request: {},
    posted: [],
    received: [],
    submitFormTrigger: false,
    loading: false
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LOADING_REQUESTS:
            return {
                ...state,
                loading: true
            }
        case ADD_FEEDBACK_REQUEST: 
            return {
                ...state,
                posted: [action.payload, ...state.posted],
                loading: false
            }
        case REMOVE_REPLYED_REQUEST:
            return {
                ...state,
                received: state.received.filter(r => {
                    return r._id !== action.payload
                })
            }
        case GET_ALL_FEEDBACK_REQUESTS:
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        case SUCCESS_SUBMIT_FORM:
            return {
                ...state,
                submitFormTrigger: action.payload
            }
        default:
            return state
    }
}