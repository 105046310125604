import { 
    ADD_FEEDBACK,
    GET_ALL_FEEDBACKS,
    EDIT_FEEDBACK_TO_VIEWED,
    SUCCESS_SUBMIT_FORM,
    LOADING_FEEDBACKS
} from '../actions/types'

const initialState = {
    feedback: {},
    posted: [],
    received: [],
    submitFormTrigger: false,
    loading: false
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LOADING_FEEDBACKS:
            return {
                ...state,
                loading: true
            }
        case ADD_FEEDBACK:
            return {
                ...state, 
                posted: [action.payload, ...state.posted],
                loading: false
            }
        case GET_ALL_FEEDBACKS:
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        case EDIT_FEEDBACK_TO_VIEWED:
            return {
                ...state,
                received: state.received.map(f => {
                    if(f._id === action.payload._id) {
                        f.wasViewed = true
                    }
                    return f
                })
            }
        case SUCCESS_SUBMIT_FORM:
            return {
                ...state, 
                submitFormTrigger: action.payload
            }
        default: 
            return state
    }
}