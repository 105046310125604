import React from 'react'

export default ({children, global}) => {
    if (global) {
        return (
            <div className="progress-global">
                {children && <div className="center-align">{children}</div>}
                <div className="progress">
                    <div className="indeterminate"></div>
                </div>
            </div>
        )
    }
    return (
        <div className="progress">
            <div className="indeterminate"></div>
        </div>
    )
}