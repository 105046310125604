import axios from 'axios'

import { LOADING_MANAGER_DATA, GET_MANAGER_DATA, GET_MANAGER_DATA_ERROR_MESSAGE } from './types'

export const getManagerData = () => dispatch => {
    dispatch({type: LOADING_MANAGER_DATA})
    axios.get('/api/admin/manager')
        .then(res => {
            dispatch({
                type: GET_MANAGER_DATA,
                payload: res.data
            })
        })
        .catch(err => {
            if (err.response.status === 403) {
                dispatch({
                    type: GET_MANAGER_DATA_ERROR_MESSAGE,
                    payload: err.response.data && err.response.data.message
                })
            } else {
                dispatch({
                    type: GET_MANAGER_DATA,
                    payload: []
                })
            }
        })
}