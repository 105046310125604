import React from 'react'
import { Link } from 'react-router-dom'

import classnames from 'classnames'

const personalLinks = [{
    name: 'Received feedback',
    url: '/personal/feedbacks/:received',
},{
    name: 'Sent feedback',
    url: '/personal/feedbacks/:posted',
},{
    name: 'Received requests',
    url: '/personal/requests',
},]

export default ({pathname}) => {
    return (
        <ul className="personal_navigation">
            {personalLinks.map(l => (
                <li className={classnames('', {
                        'active': pathname.match(l.url)
                    })}
                    key={l.name}>
                    <Link to={l.url}>{l.name}</Link>
                </li>
            ))}
        </ul>
    )
}