import React from 'react'
import classnames from 'classnames'

import rating_1 from '../../img/rating_icons/1.svg'
import rating_2 from '../../img/rating_icons/2.svg'
import rating_3 from '../../img/rating_icons/3.svg'
import rating_4 from '../../img/rating_icons/4.svg'
import rating_5 from '../../img/rating_icons/5.svg'
import rating_1_grey from '../../img/rating_icons/1_grey.svg'
import rating_2_grey from '../../img/rating_icons/2_grey.svg'
import rating_3_grey from '../../img/rating_icons/3_grey.svg'
import rating_4_grey from '../../img/rating_icons/4_grey.svg'
import rating_5_grey from '../../img/rating_icons/5_grey.svg'


export default ({rating, onChange, error, label}) => {
        const ratingText = ['Very bad', 'Needs improvement', 'Everything is OK', 'Good', 'Excellent!']
        const ratingIcons = [rating_1, rating_2, rating_3, rating_4, rating_5]
        const ratingIconsGrey = [rating_1_grey, rating_2_grey, rating_3_grey, rating_4_grey, rating_5_grey]

        function chooseUserFromAutocompeteOnEnter(correctIndex, event) {
            if (event.key === 'Enter') {
                onChange.call(null, correctIndex)
            }
        }

        const ratingList = ratingIconsGrey.map((r, index) => {
            const correctIndex = index + 1; // to avoid 0 in value
            const ratingIndex = rating === null ? rating : rating - 1; // to avoid 0 in value

            return (
                <li key={r} className={classnames('', {
                    'active': index === ratingIndex
                    })}
                    >
                    <img width="40" height="40"
                        src={ratingIndex === index ? ratingIcons[ratingIndex] : r}
                        onMouseOver={(e)=>{
                            e.target.src = ratingIcons[index]
                            e.target.parentElement.classList.add('active')
                        }}
                        onMouseOut={(e) => {
                            if (ratingIndex !== index) {
                                e.target.src = ratingIconsGrey[index]
                                e.target.parentElement.classList.remove('active')
                            }
                        }}
                        tabIndex="0"
                        onClick={onChange.bind(null, correctIndex)}
                        onKeyPress={chooseUserFromAutocompeteOnEnter.bind(null, correctIndex)}
                        alt={ratingText[index]}
                        />
                    <span className="rating-hint-text">{ratingText[index]}</span>
                </li>)
        })


        return (<div className={classnames('rating-field', {
                'invalid': !rating && error
                })}>
                {label && (<label>{label}</label>)}
                <ul className="rating-list">
                    {ratingList}
                </ul>

                {/* <Rating
                    initialRating={rating}
                    emptySymbol={ratingIconsGrey}
                    fullSymbol={ratingIcons}
                    onChange={onChange}
                    className={error && 'invalid'}
                /> */}

                {error && (<span className="helper-text">{error}</span>)}
            </div>)
    // }

}
