import React from 'react'

import RequestCard from './RequestCard'

export default ({requests, adminMode, receivedRequests, respondOnRequest, limit, fullMode, listStyle}) => {

    let requestsListHTML =  <div className="col empty-list"><span className="empty-list--title">No new requests yet.</span> Be the first to provide your colleagues with feedback. The more feedback you provide, the more you will get in return.</div>

    if(requests && requests.length) {
        let requestsArray = [];
        if (limit) {
            requests.forEach((r,i) => {
                if (i < limit) {
                    requestsArray.push(r);
                }
            })
        } else {
            requestsArray = requests
        }

        requestsListHTML = requestsArray.map(r => <RequestCard 
                                                key={r._id}
                                                request={r}
                                                adminMode={adminMode}
                                                receivedRequests={receivedRequests}
                                                respondOnRequest={respondOnRequest}
                                                fullMode={fullMode}
                                                listStyle={listStyle}/>)
    }

    return (
        <div className="col requests_list">
            <div className="row">
                {requestsListHTML}
            </div>
        </div>
    )

}