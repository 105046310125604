import React from 'react'
import classnames from 'classnames'

class Autocomplete extends React.Component {
    constructor() {
        super()

        this.state = {
            inputValue: '',
            selectedValues: [],
            filteredUsers: []
        }

        this.chooseUserFromAutocomplete = this.chooseUserFromAutocomplete.bind(this)
        this.chooseUserFromAutocompleteOnEnter = this.chooseUserFromAutocompleteOnEnter.bind(this)
        this.onChangeAutocomplete = this.onChangeAutocomplete.bind(this)
        this.removeSelectedUser = this.removeSelectedUser.bind(this)
        this.removeUserFromSelected = this.removeUserFromSelected.bind(this)
        this.onBlurAutocomplete = this.onBlurAutocomplete.bind(this)
    }

    onChangeAutocomplete(e) {
        this.setState({
            inputValue: e.target.value,
            filteredUsers: this.props.usersList.filter(u => {
                if(this.state.selectedValues.find(selUser => (selUser.email === u.email))) {
                    return false
                }
                return u.email.toLowerCase().includes(e.target.value.toLowerCase())
                        || (u.name && u.name.toLowerCase().includes(e.target.value.toLowerCase()))
            })
        })
    }

    onBlurAutocomplete(e) {
        if (!e.target.value) {
            return
        }
        if (e.relatedTarget) {
            // Do not trigger event if User has been chosen from the Autocomplete List.
            // Otherwise this will hide "onClick" and "onKeyPress" <li> events
            const autocompleteListId = "usersAutocompleteList"
            if (e.relatedTarget.id === autocompleteListId ||
                (e.relatedTarget.parentNode && e.relatedTarget.parentNode.id === autocompleteListId)) {
                return;
            }
        }

        const email = e.target.value.trim()

        if (this.state.selectedValues.find(element => element.email === email) === undefined) {
            this.selectUser({email: email})
        } else {
            this.setState({
                inputValue: ''
            })
        }
    }

    removeSelectedUser(user) {
        this.setState({
            inputValue: '',
            selectedValues: [...this.state.selectedValues.filter(u => u.email !== user.email)]
        }, () => {
            this.props.onSelect(this.state.selectedValues)

            const $input = document.getElementById(this.props.inputId)
            $input && $input.focus()
        })
    }

    removeUserFromSelected(e) {
        if (e.keyCode === 8) {
            if (e.target.value === '' && this.state.selectedValues.length > 0) {
                this.removeSelectedUser.call(null, this.state.selectedValues[this.state.selectedValues.length - 1])
            }
        } else if (e.key === 'Enter') {
            let email = this.state.inputValue.trim()
            if (!email || this.state.selectedValues.findIndex(element => element === email) !== -1) {
                return
            }

            e.preventDefault()
            this.selectUser({email: email})
        }
    }


    chooseUserFromAutocompleteOnEnter(user, event) {
        if (event.key === 'Enter') {
            this.chooseUserFromAutocomplete.call(null, user)
        }
    }

    chooseUserFromAutocomplete(user) {
        const $input = document.getElementById(this.props.inputId)

        this.setState({
            inputValue: '',
            selectedValues: [...this.state.selectedValues, user]
        }, () => {
            if (this.props.multiple) {
                this.props.onSelect(this.state.selectedValues)
                $input && $input.focus()
            } else {
                this.props.onSelect(user)
            }
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.submitFormTrigger
            && this.props.submitFormTrigger !== prevProps.submitFormTrigger) {

            this.setState({
                inputValue: '',
                selectedValues: []
            })
        }
    }

    selectUser(user) {
        this.setState({
            selectedValues: [...this.state.selectedValues, user]
        }, () => {
            if (this.props.multiple) {
                this.props.onSelect(this.state.selectedValues)
            } else {
                this.props.onSelect(user)
            }
            this.setState({
                inputValue: ''
            })
        })
    }

    render() {
        const {
            error,
            inputId,
            multiple,
            label } = this.props

        const selectedUsers = this.state.selectedValues.map(u => (
            <span className="users_autocomplete-values--value" key={u.email}>
                {u.name || u.email}
                <i className="material-icons" onClick={this.removeSelectedUser.bind(null, u)}>close</i>
            </span>
        ))

        const filteredUsers = this.state.filteredUsers.map((u, index) => (
                                    <li key={u.email+index}
                                        onClick={this.chooseUserFromAutocomplete.bind(null, u)}
                                        onKeyPress={this.chooseUserFromAutocompleteOnEnter.bind(null, u)}
                                        tabIndex={0}
                                        >
                                        {u.name || u.email}
                                    </li>
                                ))



        return (<>
                <div className="users_autocomplete">
                    <div className="users_autocomplete--input_group">
                        <label htmlFor={inputId}>{label}</label>
                        <div className="users_autocomplete-values">
                            {this.state.selectedValues.length
                            ? selectedUsers
                            : ''}
                            {multiple || this.state.selectedValues.length === 0
                                ? <div className="input-field">
                                        <input
                                            type="search"
                                            name={`emaileautocompletename${+new Date()}`}
                                            id={inputId}
                                            value={this.state.inputValue}
                                            placeholder=""
                                            onChange={this.onChangeAutocomplete}
                                            onBlur={this.onBlurAutocomplete}
                                            className={classnames('validate', {
                                                'invalid': error
                                            })}
                                            onKeyDown={this.removeUserFromSelected}
                                        />
                                    </div>
                                : ''
                            }
                        </div>
                    </div>
                    {error && (<span className="helper-text invalid">{error}</span>)}

                    {filteredUsers.length !== 0 && this.state.inputValue &&
                        <ul className="users_autocomplete-list" id="usersAutocompleteList">
                            {filteredUsers}
                        </ul>
                    }
                </div>
            </>)
    }
}


export default Autocomplete