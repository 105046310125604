import axios from 'axios'
import jwt_decode from 'jwt-decode'
import setAuthToken from '../utils/setAuthToken'

import { SET_CURRENT_USER, SET_ERRORS } from './types'

export const loginUser = userData => dispatch => {
    axios.post("/api/auth/login", userData)
        .then(res => {
            const { token } = res.data;

            localStorage.setItem('jwtToken', token);
            
            // Set token to Auth header
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded))
        })
        .catch(err => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        })
}

export const registrationUser = (userData, history) => dispatch => {
    axios.post("/api/auth/register", userData)
        .then(() => {
            return history.push('/login')
        })
        .catch(err => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
        })
}

export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    }
}

export const logoutUser = () => dispatch => {
    localStorage.removeItem('jwtToken');
    document.cookie = 'jwtToken=; Max-Age=0'
    setAuthToken(false)
    dispatch(setCurrentUser({}))
};
