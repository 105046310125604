import React from "react"
import XLSX from "xlsx"
import { connect } from 'react-redux'

import {
    getAdminData,
    editUserRights,
    uploadUsersListToDatabase,
    updateRegistredUsersWithDatabaseList,
    editUser,
    getUsersWithSameEmails,
    reassignUsersFeedbackToActiveUser,
    deleteDuplicatedUser,
    getRequestFeedbackById,
    reassignUserFeedbackRequest
} from  "../actions/adminActions"

import Preloader from './common/Preloader'
import InputField from './common/InputField'


class Admin extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedUserToManager: '',
            selectedUserToRevokeManager: '',
            selectedUserToEdit: '',
            parseFilePreloader: false,
            usersFromFile: null,
            userToEdit: null,
            openedDworpdownToManagerFilter: false,
            openedDworpdownUpdateSingle: false,
            assignManagerFilter: '',
            updateSingleUserFilter: '',
        }
        this.dropdownToManagerRef = React.createRef();
        this.dropdownUpdateSingleRef = React.createRef();

        this.assignManagersRightsToUser = this.assignManagersRightsToUser.bind(this)
        this.revokeManagersRightsToUser = this.revokeManagersRightsToUser.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onSearchChange = this.onSearchChange.bind(this)
        this.onChangeUserToEdit = this.onChangeUserToEdit.bind(this)
        this.downloadFile = this.downloadFile.bind(this)
        this.uploadUsersIntoDatabase = this.uploadUsersIntoDatabase.bind(this)
        this.updateRegisteredUsers = this.updateRegisteredUsers.bind(this)
        this.getUserToEdit = this.getUserToEdit.bind(this)
        this.submitEditUser = this.submitEditUser.bind(this)

        this.getRequestById = this.getRequestById.bind(this)
        this.handleSearchClick = this.handleSearchClick.bind(this)
        this.onFocusFilterField = this.onFocusFilterField.bind(this)
    }

    componentDidUpdate(prevProps) {
        if(this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
            this.props.history.push('/login')
        }

        if(this.props.adminData.updatedUsers !== prevProps.adminData.updatedUsers) {
            this.props.getAdminData();
            this.setState({
                userToEdit: null,
                selectedUserToEdit: ''
            })
        }

        if(this.props.adminData.userToEdit !== prevProps.adminData.userToEdit
            || (this.props.adminData.userToEdit && this.props.adminData.userToEdit._id !== prevProps.adminData.userToEdit._id)) {
            this.props.getAdminData();
            this.setState({
                userToEdit: null,
                selectedUserToEdit: ''
            })
        }
    }

    componentDidMount() {
        if(!this.props.auth.isAuthenticated) {
            this.props.history.push('/login')
        }

        this.props.getAdminData();

        document.body.addEventListener("click", this.handleSearchClick)
    }

    componentWillUnmount() {
        document.body.removeEventListener("click", this.handleSearchClick)
    }

    assignManagersRightsToUser() {
        this.props.editUserRights(this.props.adminData.allUsers.find(u => u.email === this.state.selectedUserToManager), 'add')
        this.setState({ selectedUserToManager: "", assignManagerFilter: "" })
    }

    revokeManagersRightsToUser() {
        this.props.editUserRights(this.props.adminData.managerUsers.find(u => u.email === this.state.selectedUserToRevokeManager))
    }

    getUserToEdit(email) {
        const userToEdit = [...this.props.adminData.allUsers,...this.props.adminData.managerUsers].find(u => u.email === email)
        let copyUserToEdit = null

        if (userToEdit) {
            copyUserToEdit = {...userToEdit}

            if (copyUserToEdit?.manager) {
                copyUserToEdit.manager = copyUserToEdit.manager.split(',')[0].slice(3)
            }

            this.setState({
                userToEdit: {...copyUserToEdit},
                selectedUserToEdit: copyUserToEdit.email,
                openedDworpdownUpdateSingle: false,
                updateSingleUserFilter: copyUserToEdit.name || copyUserToEdit.email
            })
            return
        }

        this.setState({
            userToEdit: null,
            selectedUserToEdit: email
        })
    }


    submitEditUser(e) {
        e.preventDefault();

        this.props.editUser(this.state.userToEdit)
    }

    onChangeUserToEdit(e) {
        if (this.state.userToEdit) {
            this.setState({
                userToEdit: {...this.state.userToEdit, ...{[e.target.name]: e.target.value}}
            })
        }
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSearchChange(e) {
        if (e.target.value === "") {
            this.setState({
                [e.target.name]: e.target.value,
                selectedUserToManager: "",
                selectedUserToEdit: "",
            })
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    downloadFile (event) {
        this.setState({
            parseFilePreloader: true
        })

        const selectedFile = event.target.files[0]
        const fileReader = new FileReader()
        const _this = this
        let jsonObject

        fileReader.onload = function(event) {
            const data = event.target.result;
            const workbook = XLSX.read(data, {
                type: "binary"
            });

            workbook.SheetNames.forEach(sheet => {
                const rowObject = XLSX.utils.sheet_to_row_object_array(
                    workbook.Sheets[sheet]
                );
                jsonObject = rowObject
            });

            _this.setState({
                usersFromFile: jsonObject,
                parseFilePreloader: false
            })
        };
        fileReader.readAsBinaryString(selectedFile);
    }

    updateRegisteredUsers () {
        this.props.updateRegistredUsersWithDatabaseList()
    }

    uploadUsersIntoDatabase() {
        const dataObject = {
            usersListFromFile: this.state.usersFromFile,
            id: this.props.adminData.userDataList ? this.props.adminData.userDataList._id : undefined
        }

        this.props.uploadUsersListToDatabase(dataObject, () => {
            this.setState({
                usersFromFile: null,
            })
        })
    }

    handleSearchClick(e){
        if (e.target instanceof Node && this.dropdownToManagerRef.current && !this.dropdownToManagerRef.current.contains(e.target)) {
            this.setState({openedDworpdownToManagerFilter: false})
        }
        if (e.target instanceof Node && this.dropdownUpdateSingleRef.current && !this.dropdownUpdateSingleRef.current.contains(e.target)) {
            this.setState({openedDworpdownUpdateSingle: false})
        }
    }

    onFocusFilterField(field){
        this.setState({[field]: true})
    }

    getRequestById() {
        if (this.state.requestIdToReasign) {
            this.setState({
                savedRequestIdToReasign: this.state.requestIdToReasign
            })
            this.props.getRequestFeedbackById({ requestId: this.state.requestIdToReasign})
        }
    }

    render () {
        const { auth, adminData } = this.props
        const managerUsersList = adminData.managerUsers && adminData.managerUsers.map(u => <li key={u._id}>{u.name}<br/>{u.email}</li>)
        const managerUsersListOptions = adminData.managerUsers && adminData.managerUsers.map(u => <option value={u.email} key={u._id}>{u.name} {u.email}</option>)
        const allUsersListOptions = adminData.allUsers && adminData.allUsers.concat(adminData.managerUsers).map(u => <option value={u.email} key={u._id}>{u.name} - {u.email}</option>)

        const filteredUsersToManager = adminData.allUsers ? adminData.allUsers.filter(u => {
                return u.email.toLowerCase().includes(this.state.assignManagerFilter.toLowerCase())
                || (u.name && u.name.toLowerCase().includes(this.state.assignManagerFilter.toLowerCase()))
            }) : [];

        const filteredUsersToSignleEdit = adminData.allUsers ? adminData.allUsers.concat(adminData.managerUsers).filter(u => {
                return u.email.toLowerCase().includes(this.state.updateSingleUserFilter.toLowerCase())
                || (u.name && u.name.toLowerCase().includes(this.state.updateSingleUserFilter.toLowerCase()))
            }) : [];

        return (<>
            <div className="container">
                <div className="row">
                    <div className="col s6">
                        <h2>
                            <small>Admin Page</small><br/>
                            Hello {auth.user.name}
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        {adminData.restrictionError
                         ? <div className="restriction-error">{adminData.restrictionError}</div>
                         : (!(adminData && adminData.allUsers && adminData.allUsers.length) && adminData.loading)
                            ? (<Preloader />)
                            : (<>
                                {/* set manager section */}
                                <div className="personal-section">
                                    <div className="manager-columns">
                                        <div className="manager-col manager-col--content">
                                            <h5>Please, choose the user who you want to <span style={{color: "green"}}>assign</span> manager's rights</h5>
                                                <div className="input-field filter-input-block" ref={this.dropdownToManagerRef}>
                                                    <input
                                                        id="assignManagerFilter"
                                                        type="text"
                                                        autoComplete="off"
                                                        name="assignManagerFilter"
                                                        placeholder="Enter name or email"
                                                        value={this.state.assignManagerFilter || ""}
                                                        onChange={this.onChange}
                                                        onFocus={() => this.onFocusFilterField("openedDworpdownToManagerFilter")}
                                                    />
                                                    {(this.state.assignManagerFilter || this.state.selectedUserToManager) && <button onClick={() => this.setState({selectedUserToManager: "", assignManagerFilter: ""})} className="filter-reset">x</button>}

                                                    {this.state.openedDworpdownToManagerFilter && filteredUsersToManager.length > 0 && <ul className="filtered-users">
                                                        {filteredUsersToManager.map((u,i) =>
                                                            <li
                                                                key={u.email+i}
                                                                onClick={() => this.setState({selectedUserToManager: u.email, openedDworpdownToManagerFilter: false, assignManagerFilter: u.name || u.email})}>{u.name} - {u.email}</li>
                                                        )}
                                                    </ul>}
                                                </div>
                                                <button className="btn btn-small" disabled={!this.state.selectedUserToManager} onClick={() => this.assignManagersRightsToUser()}>Assing rights</button>
                                            <div className="divider my-4"></div>


                                            <h5>Please, choose the user who you want to <span style={{color: "red"}}>revoke</span> manager's rights</h5>
                                                <select className="all-users-list mb-3"
                                                    name="selectedUserToRevokeManager"
                                                    style={{display: 'block'}}
                                                    value={this.state.selectedUserToRevokeManager}
                                                    onChange={this.onChange} >
                                                    <option value={''}>Choose the user</option>
                                                    {managerUsersListOptions}
                                                </select>
                                                <button className="btn btn-small" disabled={!this.state.selectedUserToRevokeManager} onClick={() => {this.revokeManagersRightsToUser(); this.setState({selectedUserToRevokeManager: ""})}}>Revoke rights</button>
                                        </div>

                                        <div className="manager-col manager-col--list">
                                            <h5>Managers list:</h5>
                                            {managerUsersList && managerUsersList.length ?
                                                <ul className="manager-users-list">
                                                    {managerUsersList}
                                                </ul>
                                                :
                                                <div>no managers yet.</div>
                                            }
                                        </div>

                                    </div>
                                </div>

                                {/* upload users list data and update with registered users */}
                                <div className="row personal-section">
                                    <div className="col">
                                        <h2 className="mt-0">Update consultants list date with the file:</h2>
                                        <div className="file-field input-field flex">
                                            <div className="btn">
                                                <span>Open file</span>
                                                <input type="file" onChange={this.downloadFile} accept=".xls,.xlsx" />
                                            </div>
                                            <div className="col9 file-path-wrapper">
                                                <input className="file-path validate" type="text" />
                                            </div>
                                        </div>
                                        {this.state.usersFromFile
                                            ? <>
                                                <div className="json-data-block">
                                                    <pre>{JSON.stringify(this.state.usersFromFile, null, 2)}</pre>
                                                </div>
                                                <p>There are {this.state.usersFromFile.length} consultants in the file.</p>
                                                <button
                                                    className="btn btn-secondary"
                                                    onClick={this.uploadUsersIntoDatabase}>
                                                        Update consultants data in database with this list
                                                </button>
                                            </>
                                            : ''}
                                        {this.props.adminData.userDataList
                                            && <p>Last update of file: <b>{new Date(this.props.adminData.userDataList.date).toUTCString()}</b></p>}
                                    </div>
                                </div>

                                {/* run job to update users with list in database  */}
                                {this.props.adminData.userDataList &&
                                    <div className="row personal-section">
                                        <h2 className="mt-0">Update all registered users with uploaded list:</h2>
                                        <div className="col s12">
                                            <button className="btn btn-secondary btn-small" onClick={this.updateRegisteredUsers}>Update all</button>
                                            {this.props.adminData.updateDatabaseError && <p style={{color: "red"}}>{this.props.adminData.updateDatabaseError}</p>}
                                            <div className="row">
                                                {this.props.adminData.registredUserButNotUpdated && <div className="col s6">
                                                    <h5>Registered but not updated users:</h5>
                                                    <ul>
                                                        {this.props.adminData.registredUserButNotUpdated.map(u => 
                                                            <li key={u._id}>{u.name} - {u.email}</li>)}
                                                    </ul>
                                                </div>}
                                                {this.props.adminData.updatedUsers && <div className="col s6">
                                                    <h5>Updated users:</h5>
                                                    <ul>
                                                        {this.props.adminData.updatedUsers.map(u => 
                                                            <li key={u._id}>{u.name} - {u.email}</li>)}
                                                    </ul>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/* form to edit single user  */}
                                <div className="row personal-section">
                                    <h2 className="mt-0">Edit single user manually:</h2>
                                    <div className="col s12">

                                        <div className="input-field filter-input-block" ref={this.dropdownUpdateSingleRef}>
                                            <input
                                                id="updateSingleUserFilter"
                                                type="text"
                                                autoComplete="off"
                                                name="updateSingleUserFilter"
                                                placeholder="Enter name or email"
                                                value={this.state.updateSingleUserFilter || ""}
                                                onChange={this.onChange}
                                                onFocus={() => this.onFocusFilterField("openedDworpdownUpdateSingle")}
                                            />
                                            {(this.state.updateSingleUserFilter || this.state.selectedUserToEdit) && <button onClick={() => this.setState({selectedUserToEdit: "", updateSingleUserFilter: "", userToEdit: null})} className="filter-reset">x</button>}

                                            {this.state.openedDworpdownUpdateSingle && filteredUsersToSignleEdit.length > 0 && <ul className="filtered-users">
                                                {filteredUsersToSignleEdit.map((u,i) =>
                                                    <li
                                                        key={u.email+i}
                                                        onClick={() => this.getUserToEdit(u.email)}>{u.name} - {u.email}</li>
                                                )}
                                            </ul>}
                                        </div>

                                        {(adminData.userToEdit && adminData.userToEdit.name && !this.state.userToEdit)
                                            && <p className="valid-text">User <b>{adminData.userToEdit.name}</b> was successfully edited</p>
                                        }

                                        {this.state.userToEdit &&
                                            <form onSubmit={this.submitEditUser}>
                                                <InputField
                                                    name="email"
                                                    type="email"
                                                    label="User's Email"
                                                    id="userToEditEmail"
                                                    onChange={this.onChangeUserToEdit}
                                                    value={this.state.userToEdit.email || ""}
                                                    required="required"
                                                />
                                                <InputField
                                                    name="name"
                                                    type="text"
                                                    label="User's Name"
                                                    id="userToEditName"
                                                    onChange={this.onChangeUserToEdit}
                                                    value={this.state.userToEdit.name || ""}
                                                    required="required"
                                                />
                                                <InputField
                                                    name="manager"
                                                    type="text"
                                                    label="User's Manager"
                                                    id="userToEditManager"
                                                    onChange={this.onChangeUserToEdit}
                                                    value={this.state.userToEdit.manager || ""}
                                                />
                                                <InputField
                                                    name="peoplePartner"
                                                    type="text"
                                                    label="User's PeoplePartner"
                                                    id="userToEditPeoplePartner"
                                                    onChange={this.onChangeUserToEdit}
                                                    value={this.state.userToEdit.peoplePartner || ""}
                                                />
                                                <button type="submit" className="btn btn-secondary">Edit User</button>
                                            </form>
                                        }
                                    </div>
                                </div>
                                <div className="row personal-section remove-duplicates">
                                    <h2 className="mt-0">Check users with same email and reasign feedbacks, requests:</h2>
                                    <div className="col s12">
                                        <button type="submit" className="btn btn-secondary" onClick={this.props.getUsersWithSameEmails}>Get Users with same email</button>
                                    </div>
                                    {adminData.deleteDuplicateMessage && <h5 className="col s12 red-text">{adminData.deleteDuplicateMessage}</h5>}
                                    <ul className="col">
                                        {adminData.duplicatedUsers && adminData.duplicatedUsers.map((group) => {
                                            return <li key={group[0].name + group[0]._id} className="col">
                                                {(adminData.reassignMessageError && adminData.userIdToAssign === group[0]._id) && <div className="col s12 red">{adminData.reassignMessageError}</div>}
                                                {(adminData.reassignMessage && adminData.userIdToAssign === group[0]._id) && <div className="col s12 green">{adminData.reassignMessage}</div>}
                                                <h5>Duplicated <b>{group[0].email} - {group[0].name}</b>:</h5>
                                                {group.map((u, index) => {
                                                    return <div key={u._id} className="row">
                                                        <div className="duplicated-row">
                                                            <div>
                                                                {u.name} - {u.email} 
                                                                <br /> {u.lastLoggedIn ? <b>{u.lastLoggedIn}</b> : <b>"not logged"</b>}
                                                            </div>
                                                            {index === 0
                                                                ? <button className="btn" onClick={() => this.props.reassignUsersFeedbackToActiveUser(u._id)}>Assign feedbacks from duplicates</button>
                                                                : <button className="btn btn-small red" disabled={adminData.deleteDuplicateMessage && adminData.userIdToRemove === u._id} onClick={() => this.props.deleteDuplicatedUser(u._id)}>Delete</button>
                                                            }
                                                        </div>
                                                    </div>
                                                })}
                                            </li>}
                                        )}
                                        {adminData.dublicatedMessage && <div className="center-align">{adminData.dublicatedMessage}</div>}
                                    </ul>
                                </div>

                                <div className="row personal-section remove-duplicates">
                                    <h2 className="mt-0">Reasign single request</h2>
                                    <InputField
                                        name="requestIdToReasign"
                                        type="text"
                                        label="Id feedback request"
                                        id="requestIdToReasign"
                                        onChange={this.onChange}
                                        value={this.state.requestIdToReasign || ""}
                                        required="required"
                                    />
                                    <button type="submit" onClick={this.getRequestById} className="btn btn-secondary">Ger request</button>
                                    {adminData.singleFeedbackRequest?.request && (<>
                                        <div>Author: {adminData.singleFeedbackRequest.request.authorId ? adminData.singleFeedbackRequest.request.authorId.email : "Author is not exist"}</div>
                                        <div>To user: {adminData.singleFeedbackRequest.request.requestedUser ? adminData.singleFeedbackRequest.request.requestedUser.email : "Requested user is not exist already"}</div>
                                        <br/>
                                        <div>
                                            <h5>Choose user to reasign:</h5>
                                            <select className="all-users-list mb-3"
                                                name="userToReasignFeedbackRequest"
                                                style={{display: 'block'}}
                                                value={this.state.userToReasignFeedbackRequest}
                                                onChange={this.onChange} >
                                                <option value={''}>Choose user to reasign</option>
                                                {managerUsersListOptions}
                                                {allUsersListOptions}
                                            </select>
                                        </div>
                                        <br/>
                                        {this.state.userToReasignFeedbackRequest &&
                                            <button className="btn" onClick={() => this.props.reassignUserFeedbackRequest({
                                                                                    toUser: adminData.allUsers.concat(adminData.managerUsers).find(u => u.email === this.state.userToReasignFeedbackRequest),
                                                                                    requestId: this.state.savedRequestIdToReasign
                                                                                })}
                                            >
                                                Reasign feedbacks request to: {this.state.userToReasignFeedbackRequest}
                                            </button>
                                        }
                                    </>)}
                                    {adminData.singleFeedbackRequest?.message && (<p>{adminData.singleFeedbackRequest?.message}</p>)}
                                </div>
                                {(adminData.loadingForms || this.state.parseFilePreloader) && <Preloader global>Updating...</Preloader>}
                            </>)
                        }
                    </div>
                </div>
            </div>
        </>);
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    adminData: state.adminData,
    errors: state.errors
})


export default connect(mapStateToProps, {
    getAdminData,
    editUserRights,
    uploadUsersListToDatabase,
    updateRegistredUsersWithDatabaseList,
    editUser,
    getUsersWithSameEmails,
    reassignUsersFeedbackToActiveUser,
    deleteDuplicatedUser,
    getRequestFeedbackById,
    reassignUserFeedbackRequest,
})(Admin)
