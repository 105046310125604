import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logoutUser} from '../../actions/authActions'
import logo from '../../img/v_feedback_logo.svg'

class Navbar extends React.Component {
    constructor() {
        super();

        this.state = {
            mobileMenu: false
        }

        this.toggleMobileMenu = this.toggleMobileMenu.bind(this)
        this.onLogoutUser = this.onLogoutUser.bind(this)
        this.closeMobileMenu = this.closeMobileMenu.bind(this)
    }

    onLogoutUser(e) {
        e.preventDefault()
        this.props.logoutUser();
        this.closeMobileMenu();
    }

    componentDidUpdate(prevProps) {
        if(this.props.auth.isAuthenticated && this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
            // this.setState('')
        }
    }

    toggleMobileMenu() {
        this.setState({
            mobileMenu: !this.state.mobileMenu
        })
    }

    closeMobileMenu() {
        if(this.state.mobileMenu) {
            this.setState({
                mobileMenu: false
            })
        }
    }

    render() {
        const { mobileMenu } = this.state
        const { isAuthenticated, user } = this.props.auth
        const newFeedbacksCount = this.props.feedbacks.received.filter(f => !f.wasViewed).length
        const newRequestsCount = this.props.requests.received.filter(r => !r.responded).length

        const guestLinks = (<>
                {/* <li><Link to="/879s7d97sdfg78sd6fg6sdfgs8d/register" onClick={this.closeMobileMenu}>Register</Link></li> */}
                {/* <li><Link to="/879s7d97sdfg78sd6fg6sdfgs8d/login" onClick={this.closeMobileMenu}>Login</Link></li> */}
            </>)

        const userLinks = (<>
            <li>
                <Link to="/personal/feedbacks/:received" onClick={this.closeMobileMenu}>
                    <span className="nav-link-text">Feedback</span>
                    <span className="received-counter received-counter--feedbacks">{newFeedbacksCount > 0 && newFeedbacksCount}</span>
                </Link>
            </li>
            <li>
                <Link to="/personal/requests" onClick={this.closeMobileMenu}>
                    <span className="nav-link-text">Requests</span>
                    <span className="received-counter">{newRequestsCount > 0 && newRequestsCount}</span>
                </Link>
            </li>
            <li className="logout-link"><a href="/#" onClick={this.onLogoutUser}>Sign out</a></li>
            </>)

        return (
            <header>
                <nav>
                    <div className="nav-wrapper">
                        <div className="container">
                            <Link to="/" className="brand-logo left">
                                <img src={logo} alt="valtech feedback"/>
                            </Link>

                            {isAuthenticated && <button className="sidenav-trigger hide-on-large-only right" onClick={this.toggleMobileMenu}>menu</button>}

                             <ul className={`right hide-on-med-and-down ${mobileMenu ? 'sidenav open' : '' }`}>
                                {(user.role && Array.isArray(user.role) && user.role.indexOf('adminManager') > -1) &&
                                    (<li><Link to="/admin/v-admin">Admin</Link></li>)}
                                {(user.role && Array.isArray(user.role) && user.role.indexOf('teamManager') > -1) &&
                                    (<li><Link to="/admin/manager">Team managment</Link></li>)}
                                {isAuthenticated ? userLinks : guestLinks}
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className={`sidenav-overlay ${mobileMenu ? 'active' : ''}`} onClick={this.toggleMobileMenu}></div>
            </header>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    feedbacks: state.feedbacks,
    requests: state.requests
})

export default connect(mapStateToProps, { logoutUser })(Navbar)