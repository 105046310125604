import React from 'react'

import rating_1 from '../../img/rating_icons/1.svg'
import rating_2 from '../../img/rating_icons/2.svg'
import rating_3 from '../../img/rating_icons/3.svg'
import rating_4 from '../../img/rating_icons/4.svg'
import rating_5 from '../../img/rating_icons/5.svg'

const ratingText = ['Very bad', 'Needs improvement', 'Everything is OK', 'Good', 'Excellent!']
const ratingIcons = [rating_1, rating_2, rating_3, rating_4, rating_5]

function getRateLayout(num) {
    return (<div className="feedback_card-rate">
                <img src={ratingIcons[num]} alt="" />
                <div className="feedback_card-rate--text">{ratingText[num]}</div>
            </div>);
}

export default function({ratingValue}) {
    switch (ratingValue) {
        case 1:
            return getRateLayout(0)
        case 2:
            return getRateLayout(1)
        case 3:
            return getRateLayout(2)
        case 4:
            return getRateLayout(3)
        case 5:
            return getRateLayout(4)
        default:
            return 'You have no appraisal'
    }
}