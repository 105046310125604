import React from "react";
import classnames from "classnames";

const TextareaField = ({
	name,
	id,
	placeholder,
	value,
	label,
	error,
	info,
	onChange,
	required,
    disabled,
    classModificator
}) => {
	return (
		<div className={classnames('input-group', classModificator)}>
            {label && <label htmlFor={id}>{label}</label>}
			<textarea
				name={name}
				id={id}
				value={value}
				placeholder={placeholder}
				onChange={onChange}
				className={classnames("materialize-textarea", {
					'invalid': error,
				})}
				required={required}
				disabled={disabled}
			></textarea>
			{error && <span className="helper-text" data-error={error}></span>}
			{info && <small className="input-info">{info}</small>}
		</div>
	);
};

export default TextareaField;
