import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'


import { getFeedbacks, editFeedbackToViewed } from '../actions/feedbacksActions'
import { getFeedbackRequests } from '../actions/requestsActions'
import { getUsers } from '../actions/usersActions'

import FeedbackList from './feedback/FeedbackList'
import FeedbackCard from './feedback/FeedbackCard'
import RequestsList from './requests/RequestsList'

import Modal from './common/Modal'
import Preloader from './common/Preloader'

import M from 'materialize-css'

import LeaveFeedback from './feedback/forms/LeaveFeedback'
import LeaveRequest from './requests/forms/LeaveRequest'


class MainPage extends React.Component {
    constructor() {
        super();
        this.state = {
            modalInstance: null,
            feedbackInModal: null,
            feedbackInModalReceived: null,
            isModalClosed: false,
            currentReplyOnRequest: null
        }

        this.closeModal = this.closeModal.bind(this)
        this.openFeedback = this.openFeedback.bind(this)
        this.respondOnRequest = this.respondOnRequest.bind(this)
    }

    // componentWillUnmount(){
    //     if(this.state.$collegeEmailSelect) {
    //         this.state.$collegeEmailSelect.map(i => i.destroy())
    //     }
    // }

    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push('/login')
        }
        
        this.props.getFeedbacks()
        this.props.getFeedbackRequests()
        this.props.getUsers()

        var elems = document.querySelectorAll('.modal')

        this.setState({
            modalInstance: M.Modal.init(elems, {
                onCloseEnd: () => {
                    this.setState({
                        isModalClosed: true,
                        currentReplyOnRequest: null
                    })
                },
                onOpenStart: () => {
                    this.setState({
                        isModalClosed: false
                    })
                }
                
            })
        })
    }

    closeModal() {
        this.state.modalInstance.map(m => m.close())
    }

    openFeedback(feedback, received){
        this.setState({
            feedbackInModal: feedback,
            feedbackInModalReceived: received
        }, () => {
            this.state.modalInstance.find(modal => modal.id === 'feedbackModal').open()
        })

        if (!feedback.wasViewed && feedback.toUser.email === this.props.auth.user.email) {
            this.props.editFeedbackToViewed(feedback)
        }
    }
    
    respondOnRequest(request) {
        this.setState({
            currentReplyOnRequest: request
        })
    }

    render () {
        const { requests, feedbacks } = this.props

        return (
            <div className="main_page">

                <div className="welcome-block">
                    <div className="container">
                        <div className="welcome-block--wrap">
                            <div className="welcome-block--header">
                                <h2>Welcome, <br className="hide-on-large-only"/>{(this.props.auth.user.name && this.props.auth.user.name.split(' ')[0]) || this.props.auth.user.email}!</h2>
                            </div>
                            <div className="welcome-block--content">
                                <a href="#leaveFeedback" className="modal-trigger welcome-block--button">Leave feedback</a>
                                <a href="#leaveRequest" className="modal-trigger welcome-block--button">Request feedback</a>
                            </div>
                        </div>
                    </div> 
                </div>
                
                <Modal modalId={'leaveFeedback'} classModificator={this.state.currentReplyOnRequest ? 'modal--request' : ''}>
                    { this.state.currentReplyOnRequest === null ? <LeaveFeedback 
                        onCloseModal={this.closeModal}
                        isModalClosed={this.state.isModalClosed}
                        users={this.props.users}
                        showConfirm={true} />
                    :
                    <LeaveFeedback
                        onCloseModal={this.closeModal}
                        isModalClosed={this.state.isModalClosed}
                        users={[]}
                        showConfirm={false}
                        currentReplyOnRequest={this.state.currentReplyOnRequest}
                        />}
                </Modal>
                <Modal modalId={'leaveRequest'} classModificator="modal--request">
                    <LeaveRequest onCloseModal={this.closeModal} isModalClosed={this.state.isModalClosed} users={this.props.users}/>
                </Modal>

                <Modal modalId={'feedbackModal'}>
                    {this.state.feedbackInModal ? <FeedbackCard feedback={this.state.feedbackInModal} receivedFeedbacks={this.state.feedbackInModalReceived} modalView={true} /> : ''}
                </Modal>


                <div className="container">
                    <div className="personal_content">
                        {(!requests.received.length && requests.loading)
                        || (!feedbacks.received.length && feedbacks.loading)
                            ? (<Preloader />)
                            : (<>
                                <div className="section-headline">
                                    <h5 className="section-headline--title">New requests</h5>
                                    {requests.received.length ? <Link to="/personal/requests">Show all</Link> : ''}
                                </div>
                                <RequestsList 
                                    requests={requests.received}
                                    receivedRequests={true}
                                    respondOnRequest={this.respondOnRequest}
                                    limit={6} />

                                <div className="section-headline">
                                    <h5 className="section-headline--title">Recent feedback</h5>
                                    {feedbacks.received.length ? <Link to="/personal/feedbacks/:received">Show all</Link> : ''}
                                </div>
                                <FeedbackList
                                    feedbacks={feedbacks.received}
                                    receivedFeedbacks={true}
                                    openFeedback={this.openFeedback}
                                    limit={6} />
                        </>)}
                    </div>
                </div>
            </div>);
    }

}


const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    feedbacks: state.feedbacks,
    requests: state.requests,
    users: state.users.users
})

export default connect(mapStateToProps, { getFeedbacks, editFeedbackToViewed, getFeedbackRequests, getUsers })(MainPage)