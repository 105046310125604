import {
    GET_MANAGER_DATA,
    LOADING_MANAGER_DATA,
    GET_MANAGER_DATA_ERROR_MESSAGE
} from '../actions/types'

const initialState = {
    managerData: {},
    loading: false
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LOADING_MANAGER_DATA:
            return {
                ...state,
                loading: true
            }
        case GET_MANAGER_DATA:
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        case GET_MANAGER_DATA_ERROR_MESSAGE:
            return {
                ...state,
                restrictionError: action.payload,
                loading: false
            }
        default:
            return state
    }
}