export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_ERRORS = 'SET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const GET_ALL_FEEDBACKS = 'GET_ALL_FEEDBACKS'
export const GET_ALL_FEEDBACK_REQUESTS = 'GET_ALL_FEEDBACK_REQUESTS'
export const GET_USERS = 'GET_USERS'
export const ADD_FEEDBACK = 'ADD_FEEDBACK'
export const ADD_FEEDBACK_REQUEST = 'ADD_FEEDBACK_REQUEST'
export const EDIT_FEEDBACK_TO_VIEWED = 'EDIT_FEEDBACK_TO_VIEWED'
export const SUCCESS_SUBMIT_FORM = 'SUCCESS_SUBMIT_FORM'
export const LOADING_REQUESTS = 'LOADING_REQUESTS'
export const LOADING_FEEDBACKS = 'LOADING_FEEDBACKS'
export const REMOVE_REPLYED_REQUEST = 'REMOVE_REPLYED_REQUEST'

export const GET_MANAGER_DATA = 'GET_MANAGER_DATA'
export const LOADING_MANAGER_DATA = 'LOADING_MANAGER_DATA'
export const GET_MANAGER_DATA_ERROR_MESSAGE = 'GET_MANAGER_DATA_ERROR_MESSAGE'
export const GET_ADMIN_DATA = 'GET_ADMIN_DATA'
export const LOADING_ADMIN_DATA = 'LOADING_ADMIN_DATA'
export const LOADING_FORMS = 'LOADING_FORMS'
export const GET_ADMIN_DATA_ERROR_MESSAGE = 'GET_ADMIN_DATA_ERROR_MESSAGE'
export const ADD_MANAGER_RIGHTS = 'ADD_MANAGER_RIGHTS'
export const REVOKE_MANAGER_RIGHTS = 'REVOKE_MANAGER_RIGHTS'
export const SET_USERS_LIST_IN_DATABASE = 'SET_USERS_LIST_IN_DATABASE'
export const UPDATE_USERS_WITH_DATABASE_LIST = 'UPDATE_USERS_WITH_DATABASE_LIST'
export const EDIT_USER = 'EDIT_USER'
export const GET_USERS_WITH_SAME_EMAIL = 'GET_USERS_WITH_SAME_EMAIL'
export const REASSIGN_FEEDBACKS_TO_ACTIVE_USER = 'REASSIGN_FEEDBACKS_TO_ACTIVE_USER'
export const DELETE_DUPLICATED_USER = 'DELETE_DUPLICATED_USER'
export const GET_FEEDBACK_REQUEST_BY_ID = 'GET_FEEDBACK_REQUEST_BY_ID'
