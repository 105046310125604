import React from 'react'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'

import { addFeedback } from '../../../actions/feedbacksActions'

import TextareaField from '../../common/TextareaField'
import RatingGroup from '../../common/RatingGroup'
import Autocomplete from '../../common/Autocomplete'

import classnames from 'classnames'


class LeaveFeedback extends React.Component {
    constructor(){
        super();
        
        this.state = {
            strengths: '',
            toimprove: '',
            subject: '',
            toUser: null,
            rating: null,
            formIsSent: false,
            anonymous: false,
            shareWithManager: false
        }
        
        this.onChange = this.onChange.bind(this)
        this.onCheck = this.onCheck.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onChangeRate = this.onChangeRate.bind(this)
        this.backToForm = this.backToForm.bind(this)
        this.chooseUserFromAutocompete = this.chooseUserFromAutocompete.bind(this)
    }

    componentDidUpdate(prevProps) {
        const isFormWasSent = this.props.feedbacks.submitFormTrigger 
                                && this.props.feedbacks.submitFormTrigger !== prevProps.feedbacks.submitFormTrigger

        if(isFormWasSent) {
            this.setState({
                strengths: '',
                toimprove: '',
                subject: '',
                rating: null,
                toUser: null,
                anonymous: false,
                shareWithManager: false
            })

            
            if(!this.props.showConfirm) {
                this.props.onCloseModal()
            } else if (this.props.showConfirm) {
                this.setState({
                    formIsSent: true
                })
            }
        }

        if (this.props.isModalClosed 
            && this.props.isModalClosed !== prevProps.isModalClosed) {
                this.setState({
                    formIsSent: false
                })
        }
        
        // when we switch between leave feeddback and reply on requets forms
        if(this.props.currentReplyOnRequest && this.props.currentReplyOnRequest !== prevProps.currentReplyOnRequest) {
            this.setState({
                toUser: this.props.currentReplyOnRequest.authorId,
                anonymous: false,
                shareWithManager: this.props.currentReplyOnRequest.shareWithManager
            })
        } else if (this.props.users.length !== prevProps.users.length) {
            this.setState({
                toUser: null,
                anonymous: false,
                shareWithManager: false
            })
        }
    }
    
    onSubmit(e) {
        e.preventDefault();
        const feedback = ({
            toUser: this.state.toUser.email,
            strengths: this.state.strengths,
            toimprove: this.state.toimprove,
            rating: this.state.rating,
            subject: this.state.subject,
            requestId: this.props.currentReplyOnRequest && this.props.currentReplyOnRequest._id,
            anonymous: this.state.anonymous,
            shareWithManager: this.state.shareWithManager
        })

        this.props.addFeedback(feedback)
    }
    
    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onCheck(e) {
        this.setState({
            [e.target.name]: !this.state[e.target.name]
        })
    }

    chooseUserFromAutocompete(data) {
        this.setState({
            toUser: data
        })
    }
    
    onChangeRate(rating) {
        this.setState({
            rating: rating
        })
    }

    backToForm() {
        this.setState({
            formIsSent: false
        })
    }

    render() {
        const { errors, users, currentReplyOnRequest } = this.props

        return (
            <div className="form-in-modal">
                <div className={classnames('confirm', {
                    'hide': !this.state.formIsSent || !this.props.showConfirm
                })}>
                    <h4>Thank you!</h4>
                    <p>Your feedback has been sent successfully <br/>
                    {/* Visit <Link to="/personal/feedbacks/:posted" className="blue-link">Sent feedbacks</Link> to find it again */}
                    </p>
                    
                    <div className="flex-row">
                    <button className="btn btn-primary" onClick={this.backToForm}>Send another one</button>
                        <button className="btn btn-primary" onClick={this.props.onCloseModal}>Close</button>
                    </div>
                </div>
                <form 
                    onSubmit={this.onSubmit}
                    noValidate
                    className={classnames('leave_feedback-form', {
                        'hide': this.state.formIsSent && this.props.showConfirm
                    })}>
                    {currentReplyOnRequest
                        ? (<div className="leave_feedback-whom">
                            <h3 className="leave_feedback-whom--title">{currentReplyOnRequest.authorId.name || currentReplyOnRequest.authorId.email} asking you for a feedback</h3>
                            <div className="leave_feedback-whom--text">{currentReplyOnRequest.text}</div>
                        </div>)
                        : <Autocomplete
                            usersList={users}
                            label={"Colleague*"}
                            onSelect={this.chooseUserFromAutocompete}
                            error={errors.toUser}
                            inputId={"leaveFeedbackToUser"}
                            submitFormTrigger={this.props.feedbacks.submitFormTrigger}/>
                    }

                    <TextareaField
                        name="subject"
                        placeholder="e.g. Demo presentation, Help with defining a feature implementation approach, etc."
                        label="Subject*"
                        id="subject"
                        onChange={this.onChange}
                        value={this.state.subject}
                        error={errors.subject}
                        classModificator="input-group--subject"
                    />

                    <RatingGroup
                        label={`How did your colleague do?*`}
                        rating={this.state.rating}
                        onChange={this.onChangeRate}
                        error={errors.rating}
                    />

                    <TextareaField
                        name="strengths"
                        placeholder="e.g. I was impressed with the way you were able to answer all the questions in a very structured manner..."
                        label="Strengths"
                        id="feedbackStrengths"
                        onChange={this.onChange}
                        value={this.state.strengths}
                        error={errors.strengths}
                        required="required"
                    />

                    <TextareaField
                        name="toimprove"
                        placeholder="e.g. You could create a more engaging presentation if you used more eye contact and a slower tempo of speech..."
                        label="Areas for improvement"
                        id="feedbackForImprove"
                        onChange={this.onChange}
                        value={this.state.toimprove}
                        error={errors.toimprove}
                        required="required"
                    />
                    < div className="check-field-group">
                        {!currentReplyOnRequest && <label htmlFor="anonymous" className="check-field">
                            <input
                                type="checkbox"
                                name="anonymous"
                                value={this.state.anonymous}
                                checked={this.state.anonymous}
                                onChange={this.onCheck}
                                // disabled={this.state.shareWithManager}
                                id="anonymous" />
                            <span>I want to be anonymous</span>
                        </label> }
                        {currentReplyOnRequest && currentReplyOnRequest.shareWithManager ?
                            <label htmlFor="shareWithManager" className="check-field">
                            <span>{`This feedback will be shared with ${currentReplyOnRequest.authorId.name ? currentReplyOnRequest.authorId.name.split(' ')[0] : currentReplyOnRequest.authorId.email}'s manager.`}</span>
                        </label>
                        : this.state.toUser !== null &&
                            <label htmlFor="shareWithManager" className="check-field">
                                <input
                                    type="checkbox"
                                    name="shareWithManager"
                                    value={this.state.shareWithManager}
                                    checked={this.state.shareWithManager}
                                    onChange={this.onCheck}
                                    // disabled={this.state.anonymous}
                                    id="shareWithManager" />
                                <span>{`Share with ${this.state.toUser.name ? this.state.toUser.name.split(' ')[0] : this.state.toUser.email}'s manager`}</span>
                            </label>
                        }
                    </div>
                    <button className="btn btn-primary">Send</button>
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    feedbacks: state.feedbacks
})

export default connect(mapStateToProps, {addFeedback})(withRouter(LeaveFeedback))