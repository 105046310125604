import React from 'react'

import RatingValue from '../common/RatingValue'
import Moment from 'react-moment'
import classnames from 'classnames'

export default ({feedback, adminMode, receivedFeedbacks, modalView, openFeedback}) => {
    let openFeedbackFunc = function() {/*console.log('empty click when open feedback is not available')*/}

    if (openFeedback) {
        openFeedbackFunc = openFeedback
    }

    return (<div className="col l4 m6 s12">
            <div
                className={classnames("feedback_card", {
                    'new-received-feedback' : !feedback.wasViewed && receivedFeedbacks,
                    'in-modal': modalView
                })}
                onClick={openFeedbackFunc.bind(null, feedback, receivedFeedbacks)}
                >
                <div className="feedback_card-wrapper">
                    {adminMode ? (<>
                        <h4 className="feedback_card-name">from {feedback.authorId && (feedback.authorId.name || feedback.authorId.email)}</h4>
                        <h4 className="feedback_card-name">to {feedback.toUser && (feedback.toUser.name || feedback.toUser.email)}</h4>
                    </>) : receivedFeedbacks ? (
                        <h4 className="feedback_card-name">
                            from {feedback.authorId 
                                ? feedback.authorId.name 
                                    ? feedback.authorId.name
                                    : feedback.authorId.email
                                : '"Anonymous"'}
                            </h4>
                    ) : (<>
                        {feedback.anonymous && modalView && <small className="feedback_card-anonymous_notice">*this feedback is anonymous</small>}
                        <h4 className="feedback_card-name">to {feedback.toUser.name ? feedback.toUser.name : feedback.toUser.email}</h4>
                    </>)}

                    <RatingValue ratingValue={feedback.rating} />

                    <div className="feedback_card-text feedback_card-text--subject">
                        {modalView && <h6 className="feedback_card-subtitle">Subject:</h6>}
                        <p>{feedback.subject}</p>
                    </div>

                    {modalView && feedback.strengths.length > 0 && <div className="feedback_card-text">
                        <h6 className="feedback_card-subtitle">Strengths:</h6>
                        <p>{feedback.strengths}</p>
                    </div>}
                    {modalView && feedback.toimprove.length > 0 && <div className="feedback_card-text">
                        <h6 className="feedback_card-subtitle">Areas for improvement:</h6>
                        <p>{feedback.toimprove}</p>
                    </div>}

                    <div className="feedback_card-date">
                        <Moment format="DD.MM.YYYY HH:mm" date={feedback.date} />
                    </div>
                    {!modalView && !feedback.wasViewed && receivedFeedbacks && <div className="feedback_card-new--label">New</div>}
                </div>
            </div>
        </div>)
    }
