import React from 'react'

export default ({modalId, children, classModificator}) =>{
    return (
        <div id={modalId} className={`modal ${classModificator}`}>
            <div className="modal-content">
                <div className="modal-content--wrapper">
                    <div className="modal-content--wrapper2">
                        {children}
                    </div>
                    <button className="modal-close "><i className="material-icons">close</i></button>
                </div>
            </div>
        </div>
    )
}
