import axios from 'axios'

import { 
    ADD_FEEDBACK_REQUEST,
    GET_ALL_FEEDBACK_REQUESTS,
    SET_ERRORS, 
    CLEAR_ERRORS,
    SUCCESS_SUBMIT_FORM,
    LOADING_REQUESTS
} from './types'

export const getFeedbackRequests = () => dispatch => {
    dispatch({type: LOADING_REQUESTS})
    axios.post('/api/feedback/requests')
        .then(res => {
            dispatch({
                type: GET_ALL_FEEDBACK_REQUESTS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ALL_FEEDBACK_REQUESTS,
                payload: []
            })
            console.error(GET_ALL_FEEDBACK_REQUESTS, err)
        })
}

export const addFeedbackRequest = (requestData) => (dispatch) => {
    dispatch(clearErrors())
    dispatch(formWasSubmited(false))
    // dispatch({type: LOADING_REQUESTS})
    axios.post('/api/feedback/leave-request', requestData)
        .then(res => {
            dispatch({
                type: ADD_FEEDBACK_REQUEST,
                payload: res.data
            })
            dispatch(formWasSubmited(true))
        })
        .catch(err => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
            console.error(ADD_FEEDBACK_REQUEST, err)
        })
}

export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS
    }
}

export const formWasSubmited = (payload) => {
    return {
        type: SUCCESS_SUBMIT_FORM,
        payload: payload
    }
}
