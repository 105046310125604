import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

const PrivateRoute = ({ component: Component, auth, ...rest}) => {
  const isAuthenticated = auth.isAuthenticated === true

  if (!isAuthenticated && (rest.location.pathname !== '/' && rest.location.pathname !== localStorage.getItem('lastPath'))) {
    localStorage.setItem('redirectTo', (rest.location.pathname + rest.location.hash))
  } else {
    // lastPath to avoid redirect when user did 'sign out' by button
    localStorage.setItem('lastPath', rest.location.pathname)
  }
  
  const redirectAfterLogin = localStorage.getItem('redirectTo')

  if (isAuthenticated && redirectAfterLogin ) {
    localStorage.removeItem('redirectTo')
  }



  return (
   <Route
    {...rest}
    render={props =>
      isAuthenticated && redirectAfterLogin ? (
        <Redirect to={redirectAfterLogin} />
      ) : isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
    }
  />
  )}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(PrivateRoute)
