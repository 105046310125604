import React from 'react'

export default () => {
    return (
        <footer className="page-footer">
          <div className="footer-copyright black">
            <div className="container center-align">
              Valtech feedback service. Developed by Valtech Ukraine, {new Date().getFullYear()} <br />
              If you faced any issues or have questions, please <a href="https://jira.valtech.com/servicedesk/customer/portal/41" target="_blank" rel="noopener noreferrer" >let us know</a>
            </div>
          </div>
        </footer>
    )
}