import React from 'react'
import { connect } from 'react-redux'

import { getFeedbackRequests } from '../../actions/requestsActions'
import { getFeedbacks } from '../../actions/feedbacksActions'
import { getUsers } from '../../actions/usersActions'

import RequestsList from '../requests/RequestsList'
import LeaveFeedback from './../feedback/forms/LeaveFeedback'
import LeaveRequest from './../requests/forms/LeaveRequest'
import PersonalNavbar from './../layout/PersonalNavbar'

import Modal from './../common/Modal'
import Preloader from './../common/Preloader'
import M from 'materialize-css'

class RequestsPage extends React.Component {
    constructor() {
        super();
        this.state = {
            modalInstance: null,
            isModalClosed: false,
            currentReplyOnRequest: null
        }
        this.closeModal = this.closeModal.bind(this)
        this.respondOnRequest = this.respondOnRequest.bind(this)
    }

    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push('/login')
        }
        
        this.props.getFeedbackRequests()
        this.props.getFeedbacks()
        this.props.getUsers()

        let $modals = document.querySelectorAll('.modal')

        this.setState({
            modalInstance: M.Modal.init($modals, {
                onCloseEnd: () => {
                    this.setState({
                        isModalClosed: true,
                        currentReplyOnRequest: null
                    })
                },
                onOpenStart: () => {
                    this.setState({
                        isModalClosed: false
                    })
                }
            })
        })
    }
    
    componentDidUpdate(prevProps) {
        if(this.props.location.hash
            && this.props.requests.received.length
            && this.props.requests.received.length !== prevProps.requests.received.length) {
            const requestId = this.props.location.hash.slice(1)
            const request = this.props.requests.received.filter(r => r._id === requestId)[0]
            
            if (request) {
                this.setState({
                    currentReplyOnRequest: request
                }, () => {
                    this.state.modalInstance.find(modal => modal.id === 'leaveFeedback').open()
                })
            }
        }
    }

    closeModal() {
        this.state.modalInstance.map(m => m.close())
    }

    respondOnRequest(request, listStyle) {
        this.setState({
            currentReplyOnRequest: request
        })
        
        if (listStyle) {
            this.state.modalInstance.find(modal => modal.id === 'leaveFeedback').open()
        }
    }

    render () {
        const { requests } = this.props

        return (<>
                <div className="personal mt-3">
                    <div className="container">
                        <PersonalNavbar pathname={this.props.location.pathname}/>
                        
                        {!(requests.received.length && requests.posted.length) && requests.loading  
                            ? (<Preloader />)
                            : (<>
                                    <RequestsList
                                        requests={requests.received}
                                        receivedRequests={true}
                                        respondOnRequest={this.respondOnRequest}
                                        fullMode={true}
                                        listStyle={true} />
                                    <a href="#leaveRequest" className="personal-btn btn modal-trigger">Request feedback</a>
                            </>)}

                    </div>
                </div>
                
                <Modal modalId={'leaveFeedback'} classModificator={this.state.currentReplyOnRequest ? 'modal--request' : ''}>
                    <LeaveFeedback
                        onCloseModal={this.closeModal}
                        isModalClosed={this.state.isModalClosed}
                        users={[]}
                        showConfirm={false}
                        currentReplyOnRequest={this.state.currentReplyOnRequest}
                        />
                </Modal>
                <Modal modalId={'leaveRequest'} classModificator="modal--request">
                    <LeaveRequest onCloseModal={this.closeModal} isModalClosed={this.state.isModalClosed} users={this.props.users}/>
                </Modal>
            </>);
    }

}


const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    requests: state.requests,
    feedbacks: state.feedbacks,
    users: state.users.users
})

export default connect(mapStateToProps, { getFeedbackRequests, getUsers, getFeedbacks })(RequestsPage)