import {
    GET_ADMIN_DATA,
    LOADING_ADMIN_DATA,
    LOADING_FORMS,
    GET_ADMIN_DATA_ERROR_MESSAGE,
    ADD_MANAGER_RIGHTS,
    REVOKE_MANAGER_RIGHTS,
    SET_USERS_LIST_IN_DATABASE,
    UPDATE_USERS_WITH_DATABASE_LIST,
    EDIT_USER,
    GET_USERS_WITH_SAME_EMAIL,
    REASSIGN_FEEDBACKS_TO_ACTIVE_USER,
    DELETE_DUPLICATED_USER,
    GET_FEEDBACK_REQUEST_BY_ID
} from '../actions/types'

const initialState = {
    adminData: {},
    loading: false,
    updatedUsers: null,
    userToEdit: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LOADING_ADMIN_DATA:
            return {
                ...state,
                loading: true
            }
        case LOADING_FORMS:
            return {
                ...state,
                loadingForms: true
            }
        case GET_ADMIN_DATA:
            return {
                ...state,
                ...action.payload,
                loading: false,
                loadingForms: false,
            }
        case GET_ADMIN_DATA_ERROR_MESSAGE:
            return {
                ...state,
                restrictionError: action.payload,
                loading: false
            }
        case ADD_MANAGER_RIGHTS:
            return {
                ...state,
                allUsers: state.allUsers.filter(u => {
                    return u._id !== action.payload._id
                }),
                managerUsers: [...state.managerUsers, action.payload],
                loadingForms: false,
            }
        case REVOKE_MANAGER_RIGHTS:
            return {
                ...state,
                allUsers: [...state.allUsers, action.payload],
                managerUsers: state.managerUsers.filter(u => {
                    return u._id !== action.payload._id
                }),
                loadingForms: false,
            }
        case SET_USERS_LIST_IN_DATABASE:
            return {
                ...state,
                userDataList: action.payload.userDataList,
                loadingForms: false,
            }
        case UPDATE_USERS_WITH_DATABASE_LIST:
            return {
                ...state,
                updatedUsers: action.payload.updatedUsers,
                registredUserButNotUpdated: action.payload.registredUserButNotUpdated,
                loadingForms: false,
                updateDatabaseError: action.payload.error,
            }
        case EDIT_USER:
            return {
                ...state,
                userToEdit: action.payload,
                loadingForms: false,
            }
        case GET_USERS_WITH_SAME_EMAIL:
            return {
                ...state,
                duplicatedUsers: action.payload.usersWithSameEmail,
                dublicatedMessage: action.payload.messege,
                loadingForms: false,
            }
        case REASSIGN_FEEDBACKS_TO_ACTIVE_USER:
            return {
                ...state,
                reassignMessageError: action.payload.error,
                reassignMessage: action.payload.message,
                userIdToAssign: action.payload.userIdToAssign,
                loadingForms: false,
            }
        case DELETE_DUPLICATED_USER:
            return {
                ...state,
                deleteDuplicateMessage: action.payload.message,
                duplicatedUsers: action.payload.userIdToRemove ? state.duplicatedUsers.map(g => g.filter(u => u._id !== action.payload.userIdToRemove)) : state.duplicatedUsers,
                loadingForms: false,
            }
        case GET_FEEDBACK_REQUEST_BY_ID:
            return {
                ...state,
                singleFeedbackRequest: action.payload,
                loadingForms: false,
            }
        default:
            return state
    }
}
