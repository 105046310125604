import React from "react"
import { connect } from 'react-redux'


class Login extends React.Component {
    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push('/')
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
            this.props.history.push('/')
        }
    }


    render () {
        function temporalHackRefactorThis() {
            window.location.href = process.env.REACT_APP_LOGIN_URL;
        }

        return (
            <div className="container row login_page">
                <div className="col">
                    <div className="row">
                        <div className="col m8 login_content">
                            <h1>Exchange feedback with your <br className="show-on-large"/>colleagues using our internal tool: Valtech feedback</h1>
                            <p>We live our feedback culture where every consultant gets to voice their opinion <br className="show-on-large"/>
                                and openly provide feedback to another consultant regardless of hierarchy. <br className="show-on-large"/>
                                Encourage, appreciate, recognize, and help colleagues improve. </p>
                            <button onClick={temporalHackRefactorThis} className="btn btn-primary login-button">Log in</button>

                            <div className="figure_dialog figure_dialog--1 show-on-large"></div>
                            <div className="figure_dialog figure_dialog--2 show-on-large"></div>
                            <div className="figure_dialog figure_dialog--3 show-on-large"></div>
                        </div>
                        <div className="col m4"></div>
                    </div>
                </div>
            </div>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    // errors: state.errors
})

export default connect(mapStateToProps, {})(Login)
