import React from 'react'
import { connect } from 'react-redux'

import { getFeedbacks, editFeedbackToViewed } from '../../actions/feedbacksActions'
import { getFeedbackRequests } from '../../actions/requestsActions'
import { getUsers } from '../../actions/usersActions'

import FeedbackList from './FeedbackList'
import FeedbackCard from './FeedbackCard'
import LeaveFeedback from './forms/LeaveFeedback'
import PersonalNavbar from './../layout/PersonalNavbar'

import Modal from './../common/Modal'
import Preloader from './../common/Preloader'
import M from 'materialize-css'



class FeedbacksPage extends React.Component {
    constructor() {
        super()
        this.state = {
            modalInstance: null,
            isModalClosed: false,
            feedbackInModal: null,
            feedbackInModalReceived: null
        }

        this.closeModal = this.closeModal.bind(this)
        this.openFeedback = this.openFeedback.bind(this)
    }

    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push('/login')
        }
        
        this.props.getFeedbacks()
        this.props.getFeedbackRequests()
        this.props.getUsers()

        let $modals = document.querySelectorAll('.modal')

        this.setState({
            modalInstance: M.Modal.init($modals, {
                onCloseEnd: () => {
                    this.setState({
                        isModalClosed: true
                    })
                },
                onOpenStart: () => {
                    this.setState({
                        isModalClosed: false
                    })
                }
            })
        })
    }

    componentDidUpdate(prevProps) {
        // open received feedback from hash after loading it to store
        if(this.props.location.hash
            && this.props.match.params.handlepage === ':received'
            && this.props.feedbacks.received.length
            && this.props.feedbacks.received.length !== prevProps.feedbacks.received.length) {
            const feedbackId = this.props.location.hash.slice(1)
            const feedback = this.props.feedbacks.received.filter(f => f._id === feedbackId)[0]

            if (feedback) {
                this.setState({
                    feedbackInModal: feedback,
                    feedbackInModalReceived: true
                }, () => {
                    this.state.modalInstance.find(modal => modal.id === 'feedbackModal').open()

                    if (!feedback.wasViewed && feedback.toUser.email === this.props.auth.user.email) {
                        this.props.editFeedbackToViewed(feedback)
                    }
                })
            }
        }
    }

    closeModal() {
        this.state.modalInstance.map(m => m.close())
    }

    openFeedback(feedback, received){
        this.setState({
            feedbackInModal: feedback,
            feedbackInModalReceived: received
        }, () => {
            this.state.modalInstance.find(modal => modal.id === 'feedbackModal').open()
        })

        if (!feedback.wasViewed && feedback.toUser.email === this.props.auth.user.email) {
            this.props.editFeedbackToViewed(feedback)
        }
    }

    render () {
        const { feedbacks } = this.props

        return (<>
                <div className="personal mt-3">
                    <div className="container">
                        <PersonalNavbar pathname={this.props.location.pathname}/>

                        {!(feedbacks.received.length && feedbacks.posted.length) && feedbacks.loading
                            ? (<Preloader />)
                            : (<>
                                    {this.props.match.params.handlepage === ':received'
                                    ? <>
                                        <FeedbackList feedbacks={feedbacks.received} receivedFeedbacks={true} isPersonalPage={true} openFeedback={this.openFeedback}/>
                                    </>
                                    : <>
                                        <FeedbackList feedbacks={feedbacks.posted} openFeedback={this.openFeedback}/>
                                    </>}
                                <a href="#leaveFeedback" className="personal-btn btn modal-trigger">Leave feedback</a>
                            </>)}
                    </div>
                </div>
                <Modal modalId={'feedbackModal'}>
                    {this.state.feedbackInModal ? <FeedbackCard feedback={this.state.feedbackInModal} receivedFeedbacks={this.state.feedbackInModalReceived} modalView={true} /> : ''}
                </Modal>
                <Modal modalId={'leaveFeedback'}>
                    <LeaveFeedback 
                        onCloseModal={this.closeModal}
                        isModalClosed={this.state.isModalClosed}
                        users={this.props.users}
                        showConfirm={true} />
                </Modal>
            </>);
    }

}


const mapStateToProps = state => ({
    auth: state.auth,
    feedbacks: state.feedbacks,
    requests: state.requests,
    users: state.users.users
})

export default connect(mapStateToProps, { getFeedbacks, getFeedbackRequests, getUsers, editFeedbackToViewed })(FeedbacksPage)