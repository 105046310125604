import React from 'react'

import FeedbackCard from './FeedbackCard'

export default ({feedbacks, adminMode, receivedFeedbacks, limit, openFeedback, managerPage}) => {
    let feedbacksListHTML = <div className="col empty-list"><span className="empty-list--title">You have not shared your feedback with anyone yet.</span> It is a great time to start now! Share your feedback - encourage, appreciate, recognize, and help colleagues improve.</div>
    if (managerPage) {
        feedbacksListHTML = <div className="col empty-list"><span className="empty-list--title">There is no shared feedback.</span></div>
    } else if(receivedFeedbacks) {
        feedbacksListHTML = <div className="col empty-list"><span className="empty-list--title">No feedback was provided to you yet.</span> Try to request feedback from your colleagues.</div>
    }

    if(feedbacks && feedbacks.length) {
        let feedbacksArray = [];

        if (limit) {
            feedbacks.forEach((r,i) => {
                if (i < limit) {
                    feedbacksArray.push(r);
                }
            })
        } else {
            feedbacksArray = feedbacks
        }

        feedbacksListHTML = feedbacksArray.map(f => <FeedbackCard key={f._id} feedback={f} adminMode={adminMode} receivedFeedbacks={receivedFeedbacks} openFeedback={openFeedback}/>)
    }

    return (
        <div className="col feedbacks_list">
            <div className="row">
                {feedbacksListHTML}
            </div>
        </div>
    )

}