import React from "react"
import { connect } from 'react-redux'

import { getManagerData } from  "../actions/managerActions"

import FeedbackList from './feedback/FeedbackList'
import FeedbackCard from './feedback/FeedbackCard'

import Modal from './common/Modal'
import Preloader from './common/Preloader'
import M from 'materialize-css'


class ManagerPage extends React.Component {
    constructor() {
        super();
        this.state = {
            feedbacks: null,
            filterSelectedUser: "",
            feedbackInModal: null,
            filteredUsers: [],
            filterUserField: "",
            openedFilterDworpdown: false
        }

        this.dropdownRef = React.createRef();

        this.closeModal = this.closeModal.bind(this)
        this.openFeedback = this.openFeedback.bind(this)
        this.onChange = this.onChange.bind(this)
        this.handleSearchClick = this.handleSearchClick.bind(this)
        this.onFocusFilterField = this.onFocusFilterField.bind(this)
    }

    componentDidUpdate(prevProps) {
        if(this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
            this.props.history.push('/login')
        }
    }

    componentDidMount() {
        if(!this.props.auth.isAuthenticated) {
            this.props.history.push('/login')
        }

        this.props.getManagerData();

        var elems = document.querySelectorAll('.modal')

        this.setState({
            modalInstance: M.Modal.init(elems, {
                onCloseEnd: () => {
                    this.setState({
                        isModalClosed: true
                    })
                },
                onOpenStart: () => {
                    this.setState({
                        isModalClosed: false
                    })
                }
            })
        })

        document.body.addEventListener("click", this.handleSearchClick)
    }


    componentWillUnmount() {
        document.body.removeEventListener("click", this.handleSearchClick)
    }

    onFocusFilterField(){
        this.setState({openedFilterDworpdown: true})
    }

    handleSearchClick(e){
        if (e.target instanceof Node && this.dropdownRef.current && !this.dropdownRef.current.contains(e.target)) {
            this.setState({openedFilterDworpdown: false})
        }
    }

    closeModal() {
        this.state.modalInstance.map(m => m.close())
    }


    openFeedback(feedback){
        this.setState({
            feedbackInModal: feedback
        }, () => {
            this.state.modalInstance.find(modal => modal.id === 'feedbackModal').open()
        })
    }

    onChange(e) {
        if (e.target.value === "") {
            this.setState({
                [e.target.name]: e.target.value,
                filterSelectedUser: ""
            })
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    render () {
        const { auth, managerData } = this.props
        const coWorkers = managerData.managersPeople && managerData.managersPeople.map(u => (<option value={u.email} key={u.email}>{u.email || u.name}</option>))
        const filteredUsers = managerData.managersPeople ? managerData.managersPeople.filter(u => {
            return u.email.toLowerCase().includes(this.state.filterUserField.toLowerCase())
            || (u.name && u.name.toLowerCase().includes(this.state.filterUserField.toLowerCase()))
        }) : [];

        const filteredFeedbacks = this.props.managerData.received
                                    ? this.state.filterSelectedUser
                                        ? this.props.managerData.received.filter(f => f.toUser && f.toUser.email === this.state.filterSelectedUser)
                                        : this.props.managerData.received
                                    : null
        return (<>
            <div className="container personal">
                <div className="row">
                    <div className="col s6">
                        <h2>Hello {auth.user.name}</h2>
                    </div>
                    {coWorkers && coWorkers.length > 1 &&
                        <div className="col s6">
                            <div className="input-field filter-input-block" ref={this.dropdownRef}>
                                <input
                                    id="filterUserField"
                                    type="search"
                                    autoComplete="off"
                                    name="filterUserField"
                                    placeholder="Enter name or email"
                                    value={this.state.filterUserField || ""}
                                    onChange={this.onChange}
                                    onFocus={this.onFocusFilterField}
                                />

                                {(this.state.filterUserField || this.state.filterSelectedUser) && <button onClick={() => this.setState({filterSelectedUser: "", filterUserField: ""})} className="filter-reset">x</button>}

                                {this.state.openedFilterDworpdown && filteredUsers.length > 0 && <ul className="filtered-users">
                                    {filteredUsers.map((u,i) =>
                                        <li
                                            key={u.email+i}
                                            onClick={() => this.setState({filterSelectedUser: u.email, openedFilterDworpdown: false, filterUserField: u.name})}>{u.name} - {u.email}</li>
                                    )}
                                </ul>}
                            </div>
                        </div>
                    }
                </div>
                <div className="row">
                    <div className="col s6">
                        {managerData.restrictionError
                        ? <div className="restriction-error">{managerData.restrictionError}</div>
                        : (!(managerData && managerData.received && managerData.received.length) && managerData.loading)
                            ? (<Preloader />)
                            : (<>
                                    <FeedbackList
                                        feedbacks={filteredFeedbacks}
                                        receivedFeedbacks={true}
                                        openFeedback={this.openFeedback}
                                        adminMode={true}
                                        managerPage={true} />
                                </>)
                        }
                    </div>
                </div>
            </div>

            <Modal modalId={'feedbackModal'}>
                {this.state.feedbackInModal ? <FeedbackCard feedback={this.state.feedbackInModal} receivedFeedbacks={true} modalView={true} /> : ''}
            </Modal>
        </>);
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    managerData: state.managerData
})


export default connect(mapStateToProps, {getManagerData})(ManagerPage)