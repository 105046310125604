import React from 'react'

import Moment from 'react-moment'

export default ({request, adminMode, receivedRequests, fullMode, listStyle, respondOnRequest}) => {
    const requestAutorName = request.authorId.name ? request.authorId.name : request.authorId.email
    let respondOnRequestFunc = f => {/*console.log('empty call on "respondOnRequest"')*/}

    if(respondOnRequest) {
        respondOnRequestFunc = respondOnRequest
    }

    return (
        <div className="col l4 m6 s12">
            <div className="feedback_card feedback_card-request"
                onClick={listStyle ? respondOnRequestFunc.bind(null, request, listStyle) : f=>f}
            >
                {receivedRequests
                    ? (listStyle
                        ? <h4 className="feedback_card-name">from {requestAutorName}</h4>
                        : <h4 className="feedback_card-name">{requestAutorName} asking you for a feedback</h4>)
                    : (<h4 className="feedback_card-name">to {request.requestedUser.email}</h4>)
                }

                {listStyle || (fullMode && request.text)
                    ? (<div className="feedback_card-text">
                        <h6 className="feedback_card-subtitle">Text:</h6>
                        <p>{request.text}</p>
                    </div>)
                    : ''
                }

                <div className="feedback_card-date">
                    <Moment format="DD.MM.YYYY HH:mm" date={request.date} />
                </div>
            </div>
            {receivedRequests ?
                (<a href="#leaveFeedback" className="feedback_card-button modal-trigger" onClick={respondOnRequest.bind(null, request)}>
                    Reply
                </a>)
                :
                (<button className="feedback_card-button" onClick={respondOnRequest}>
                    To reply
                </button>)
            }
    </div>)
}
