import React from "react"
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { registrationUser } from '../../actions/authActions'
import InputField from '../common/InputField'


class Registration extends React.Component {
    constructor() {
        super();

        this.state = {
            email: "", 
            password: "",
            name: "",
        }

        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }
    
    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push('/')
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
            this.props.history.push('/login')
        }
    }

    onChange(e) {
        this.setState({ 
            [e.target.name]: e.target.value
        })
    }

    onSubmit(e) {
        e.preventDefault();

        const newUser = {
            email: this.state.email,
            password: this.state.password,
            name: this.state.name
        }

        this.props.registrationUser(newUser, this.props.history)
    }

    render () {
        const { errors } = this.props

        return (
            <div className="container row">
                <div className="col m3"></div>
                <div className="col s12 m6">
                    <h2>Registration</h2>

                    {errors.registerError && (
                        <p className="alert">{errors.registerError}</p>)
                    }

                    <form onSubmit={this.onSubmit} noValidate>
                        <InputField
                            name="email"
                            type="email"
                            label="Email"
                            id="registerEmail"
                            onChange={this.onChange}
                            error={errors.email}
                            required="required"
                        />
                        
                        <InputField
                            name="password"
                            type="password"
                            label="Password"
                            id="registerPassword"
                            onChange={this.onChange}
                            error={errors.password}
                            required="required"
                        />
                        <InputField
                            name="name"
                            type="text"
                            label="Name"
                            id="userName"
                            onChange={this.onChange}
                            error={errors.name}
                            required="required"
                        />

                        <button className="btn btn-primary" type="submit">Register</button>
                    </form>

                    <div className="center">
                        <Link to="/879s7d97sdfg78sd6fg6sdfgs8d/login" onClick={this.closeMobileMenu}>Log in</Link>
                    </div>
                </div>
            </div>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
})
  
export default connect(mapStateToProps, { registrationUser })(withRouter(Registration))