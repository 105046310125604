import { combineReducers } from  "redux"
import authReducer from "./authReducer"
import feedbacksReducer from "./feedbacksReducer"
import requestsReducer from "./requestsReducer"
import usersReducer from "./usersReducer"
import errorReducer from "./errorReducer"
import managerReducer from "./managerReducer"
import adminReducer from "./adminReducer"

export default combineReducers({
    auth: authReducer,
    errors: errorReducer,
    feedbacks: feedbacksReducer,
    requests: requestsReducer,
    users: usersReducer,
    managerData: managerReducer,
    adminData: adminReducer,
})