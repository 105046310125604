import React from 'react'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'

import { addFeedbackRequest } from '../../../actions/requestsActions'

import TextareaField from '../../common/TextareaField'
import Autocomplete from '../../common/Autocomplete'

import classnames from 'classnames'


class RequestFeedback extends React.Component {
    constructor(){
        super();
        
        this.state = {
            text: '',
            toUsers: [],
            formIsSent: false,
            shareWithManager: false
        }
        
        this.onChange = this.onChange.bind(this)
        this.onCheck = this.onCheck.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.backToForm = this.backToForm.bind(this)
        this.chooseUserFromAutocompete = this.chooseUserFromAutocompete.bind(this)
    }

    componentDidUpdate(prevProps) {
        const isFormWasSent = this.props.requests.submitFormTrigger 
                                && this.props.requests.submitFormTrigger !== prevProps.requests.submitFormTrigger
        if(isFormWasSent) {

            this.setState({
                text: '',
                toUsers: [],
                formIsSent: true,
                shareWithManager: false
            })
            // Form was submited
        }

        if (this.props.isModalClosed 
            && this.props.isModalClosed !== prevProps.isModalClosed) {
                this.setState({
                    formIsSent: false
                })
        }
    }

    onSubmit(e) {
        e.preventDefault();

        let toUserEmail = null

        if (this.state.toUsers.length) {
            toUserEmail = this.state.toUsers.map(u => u.email)
        }
        
        const feedbackRequest = ({
            toUsers: toUserEmail,
            text: this.state.text,
            authorId: this.props.auth.user.id,
            shareWithManager: this.state.shareWithManager
        })
        
        this.props.addFeedbackRequest(feedbackRequest)
    }
    
    onChange(e) {
        this.setState({ 
            [e.target.name]: e.target.value
        })
    }

    onCheck(e) {
        this.setState({
            [e.target.name]: !this.state[e.target.name]
        })
    }

    chooseUserFromAutocompete(data) {
        this.setState({
            toUsers: data
        })
    }

    backToForm() {
        this.setState({
            formIsSent: false
        })
    }

    render() {
        const { errors, users} = this.props

        // const usersManagers = this.state.toUsers.map(u => {
        //     return `${u.name ? u.name.split(' ')[0] : u.email}'s`
        // })

        return (
            <div className="form-in-modal">
                <div className={classnames('confirm', {
                    'hide': !this.state.formIsSent
                })}>
                    <h4>Thank you!</h4>
                    <p>Your request has been sent successfully</p>
                    <div className="flex-row">
                        <button className="btn btn-primary" onClick={this.backToForm}>Send another one</button>
                        <button className="btn btn-primary" onClick={this.props.onCloseModal}>Close</button>
                    </div>
                </div>
                <form 
                    onSubmit={this.onSubmit}
                    noValidate
                    className={classnames('request-feedback-form', {
                        'hide': this.state.formIsSent
                    })} >
                   
                    <Autocomplete
                        usersList={users}
                        label={"Request feedback from one or more colleagues*"}
                        onSelect={this.chooseUserFromAutocompete}
                        error={errors.toUsers}
                        inputId="leaveRequestToUser"
                        multiple={true}
                        submitFormTrigger={this.props.requests.submitFormTrigger}/>
                    

                    <TextareaField
                        name="text"
                        placeholder="e.g. I would like to request your feedback about my mentoring skills, how do you find my mentoring approach so far?..."
                        label="What would you like to receive a feedback about?"
                        id="feedbackText"
                        onChange={this.onChange}
                        value={this.state.text}
                        error={errors.text}
                        required="required"
                    />

                    < div className="check-field-group">
                        {this.state.toUsers.length !== 0 &&
                            <label htmlFor="shareWithManagerRequest" className="check-field">
                                <input
                                    type="checkbox"
                                    name="shareWithManager"
                                    value={this.state.shareWithManager}
                                    checked={this.state.shareWithManager}
                                    onChange={this.onCheck}
                                    id="shareWithManagerRequest" />
                                    <span>{`Share with my manager`}</span>
                            </label>
                        }
                    </ div>

                    <button className="btn btn-primary">Send</button>
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    requests: state.requests
})

export default connect(mapStateToProps, {addFeedbackRequest})(withRouter(RequestFeedback))