import axios from 'axios'
import { logoutUser } from './authActions'

import {
    GET_USERS,
} from './types'

export const getUsers = () => dispatch => {
    axios.get('/api/feedback/users')
        .then(res => {
            dispatch({
                type: GET_USERS,
                payload: res.data
            })
        })
        .catch(err => {
            // when user session is dropped
            if (err && err.response && err.response.status === 401) {
                dispatch(logoutUser())
            } else {
                dispatch({
                    type: GET_USERS,
                    payload: []
                })}
            }
        )
}