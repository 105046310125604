import React, { Component } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import jwt_decode from 'jwt-decode'
import setAuthToken from './utils/setAuthToken'
import { setCurrentUser, logoutUser } from './actions/authActions'

import { Provider } from 'react-redux'
import store from './store'

import PrivateRoute from './components/common/PrivateRoute'
import PreloaderPage from './components/common/PreloaderPage'

import Login from './components/auth/Login'
import LoginWithoutSSO from './components/auth/LoginWithoutSSO'
import Registration from './components/auth/Registration'

import Navbar from './components/layout/Navbar'
import Footer from './components/layout/Footer'
import MainPage from './components/MainPage'
import FeedbacksPage from './components/feedback/FeedbacksPage'
import RequestsPage from './components/requests/RequestsPage'
import ManagerPage from './components/ManagerPage'
import Admin from './components/Admin'
import AdminToUpdateData from './components/AdminToUpdateData'
import NotFound from './components/NotFound'
import GA from './utils/GoogleAnalytics'

import "./App.scss"

console.warn = () => {}

if (!localStorage.jwtToken
	&& document.cookie
	&& document.cookie.indexOf("jwtToken") !== -1) {
	localStorage.jwtToken = decodeURI(document.cookie
		.split(";")
		.find(row => row.trim().startsWith("jwtToken"))
		.split('=')[1]);
}

if (localStorage.jwtToken) {
	setAuthToken(localStorage.jwtToken)
	const decoded = jwt_decode(localStorage.jwtToken)
	store.dispatch(setCurrentUser(decoded))

	const currentTime = Date.now() / 1000
	if (decoded.exp < currentTime) {
	  store.dispatch(logoutUser())
	  window.location.href = '/login'
	}
}

class App extends Component {
    componentDidMount() {
		if (window.location.pathname.indexOf("//") !== -1) {
			const loc = window.location
			loc.replace(loc.pathname.replace(/\/\//g, "/") + loc.hash)
		}
	}

	render() {
		return (
			<Provider store={store}>
				<Router>
					<div className="app-container">
						{ GA.init() && <GA.RouteTracker /> }
						<main className="main">
							<Navbar />

							<Switch>
								<PrivateRoute exact path="/" component={MainPage} />
								<Route path="/login" component={Login} />
								<Route path="/879s7d97sdfg78sd6fg6sdfgs8d/login" component={LoginWithoutSSO} />
								<Route path="/879s7d97sdfg78sd6fg6sdfgs8d/register" component={Registration} />
								<PrivateRoute path="/personal/feedbacks/:handlepage" component={FeedbacksPage} />
								<PrivateRoute path="/personal/requests" component={RequestsPage} />
								<PrivateRoute path="/admin/manager" component={ManagerPage} />
								<PrivateRoute path="/admin/v-admin" component={Admin} />
								{/* temp route. to remove after updating data  */}
								{/* <PrivateRoute path="/admin/update-feedbacks-with-shared-true-according-to-request" component={AdminToUpdateData} /> */}
								<PrivateRoute path="/admin/update-users-email-to-lowercase" component={AdminToUpdateData} />

								<Route path="/api/auth/login/sso" component={PreloaderPage} />
								<Route path="*" component={NotFound} />
							</Switch>
						</main>
						<Footer />
					</div>
				</Router>
			</Provider>
		);
	}
}

export default App;
