import React from 'react'

export default () => {
  return (
    <div className="container">
      <h1>Page Not Found</h1>
      <p>Sorry, this page does not exist</p>
    </div>
  )
}
