import axios from 'axios'

import {
    ADD_FEEDBACK,
    GET_ALL_FEEDBACKS,
    EDIT_FEEDBACK_TO_VIEWED,
    SET_ERRORS,
    CLEAR_ERRORS,
    SUCCESS_SUBMIT_FORM,
    LOADING_FEEDBACKS,
    REMOVE_REPLYED_REQUEST
} from './types'

export const getFeedbacks = () => dispatch => {
    dispatch({type: LOADING_FEEDBACKS})
    
    axios.post('/api/feedback/feedbacks')
        .then(res => {
            dispatch({
                type: GET_ALL_FEEDBACKS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ALL_FEEDBACKS,
                payload: []
            })
        })
}

export const addFeedback = (feedbackData) => dispatch => {
    dispatch(clearErrors())
    dispatch(formWasSubmited(false))
    // dispatch({type: LOADING_FEEDBACKS})
    axios.post('/api/feedback/leave-feedback', feedbackData)
        .then(res => {
            dispatch({
                type: ADD_FEEDBACK,
                payload: res.data
            })

            if (feedbackData.requestId) {
                dispatch({
                    type: REMOVE_REPLYED_REQUEST,
                    payload: feedbackData.requestId
                })
            }

            dispatch(formWasSubmited(true))
        })
        .catch(err => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            })
            console.error(ADD_FEEDBACK, err)
        })
}

export const editFeedbackToViewed = (feedbackData) => dispatch => {
    axios.post('/api/feedback/edit', feedbackData)
        .then(res => {
            dispatch({
                type: EDIT_FEEDBACK_TO_VIEWED,
                payload: res.data
            })
        })
        .catch(err => {
            console.error(EDIT_FEEDBACK_TO_VIEWED, err)

            // dispatch({
            //     type: EDIT_FEEDBACK_TO_VIEWED,
            //     payload: res.data
            // })

            // TODO: make error handle for show requests error messages in some popup
        })
}

export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS
    }
}

export const formWasSubmited = (payload) => {
    return {
        type: SUCCESS_SUBMIT_FORM,
        payload: payload
    }
}