import axios from 'axios'

import {
    LOADING_ADMIN_DATA,
    LOADING_FORMS,
    GET_ADMIN_DATA,
    GET_ADMIN_DATA_ERROR_MESSAGE,
    REVOKE_MANAGER_RIGHTS,
    ADD_MANAGER_RIGHTS,
    SET_USERS_LIST_IN_DATABASE,
    UPDATE_USERS_WITH_DATABASE_LIST,
    EDIT_USER,
    GET_USERS_WITH_SAME_EMAIL,
    REASSIGN_FEEDBACKS_TO_ACTIVE_USER,
    DELETE_DUPLICATED_USER,
    GET_FEEDBACK_REQUEST_BY_ID,
} from './types'

export const getAdminData = () => dispatch => {
    dispatch({type: LOADING_ADMIN_DATA})
    axios.get('/api/admin/v-admin')
        .then(res => {
            dispatch({
                type: GET_ADMIN_DATA,
                payload: res.data
            })
        })
        .catch(err => {
            if (err.response.status === 403) {
                dispatch({
                    type: GET_ADMIN_DATA_ERROR_MESSAGE,
                    payload: err.response.data && err.response.data.message
                })
            } else {
                dispatch({
                    type: GET_ADMIN_DATA,
                    payload: []
                })
            }
        })
}

export const editUserRights = (user, typeEdit) => dispatch => {
    dispatch({ type: LOADING_FORMS })
    axios.post('/api/admin/edit-user-rights', user, typeEdit)
        .then(res => {
            if(typeEdit === 'add') {
                dispatch({
                    type: ADD_MANAGER_RIGHTS,
                    payload: res.data
                })
            } else {
                dispatch({
                    type: REVOKE_MANAGER_RIGHTS,
                    payload: res.data
                })
            }
        })
        .catch(err => {
            console.error(err.response)
        })
}

export const uploadUsersListToDatabase = (usersListData, cb) => dispatch => {
    dispatch({ type: LOADING_FORMS })
    axios.post('/api/admin/upload-users-data', usersListData)
        .then(res => {
            dispatch({
                type: SET_USERS_LIST_IN_DATABASE,
                payload: res.data
            })
            cb()
        })
        .catch(err => {
            console.error(err.response)
        })
}

export const updateRegistredUsersWithDatabaseList = () => dispatch => {
    dispatch({ type: LOADING_FORMS })
    axios.get('/api/admin/update-users-fields')
        .then(res => {
            dispatch({
                type: UPDATE_USERS_WITH_DATABASE_LIST,
                payload: res.data
            })
        })
        .catch(err => {
            console.error(err.response)
        })
}

export const editUser = (userToEdit) => dispatch => {
    dispatch({ type: LOADING_FORMS })
    axios.post('/api/admin/edit-user', userToEdit)
        .then(res => {
            dispatch({
                type: EDIT_USER,
                payload: res.data
            })
        })
        .catch(err => {
            console.error(err.response)
        })
}

export const getUsersWithSameEmails = () => dispatch => {
    dispatch({ type: LOADING_FORMS })

    axios.get("/api/admin/find-duplicate-emails")
        .then(res => {
            dispatch({
                type: GET_USERS_WITH_SAME_EMAIL,
                payload: res.data
            })
        })
        .catch(err => {
            console.log(err)
            dispatch({
                type: GET_USERS_WITH_SAME_EMAIL,
                payload: []
            })
        })
}

export const reassignUsersFeedbackToActiveUser = (id) => dispatch => {
    dispatch({ type: LOADING_FORMS })

    axios.post("/api/admin/assign-missed-feedbacks", {id})
        .then(res => {
            dispatch({
                type: REASSIGN_FEEDBACKS_TO_ACTIVE_USER,
                payload: res.data
            })
        })
        .catch(err => {
            console.log(err)
            if (err.response.status === 400) {
                dispatch({
                    type: REASSIGN_FEEDBACKS_TO_ACTIVE_USER,
                    payload: err.response.data && err.response.data
                })
            } else {
                dispatch({
                    type: REASSIGN_FEEDBACKS_TO_ACTIVE_USER,
                    payload: []
                })
            }
        })
}

export const deleteDuplicatedUser = (id) => dispatch => {
    dispatch({ type: LOADING_FORMS })

    axios.post("/api/admin/delete-duplicated-user", {id})
        .then(res => {
            dispatch({
                type: DELETE_DUPLICATED_USER,
                payload: res.data
            })
        })
        .catch(err => {
            console.log(err)
            dispatch({
                type: DELETE_DUPLICATED_USER,
                payload: []
            })
        })
}

export const getRequestFeedbackById = (requestId) => dispatch => {
    dispatch({ type: LOADING_FORMS })

    axios.post("/api/admin/get-request-by-id", requestId)
        .then(res => {
            dispatch({
                type: GET_FEEDBACK_REQUEST_BY_ID,
                payload: res.data
            })
        })
        .catch(err => {
            console.log(err)
            dispatch({
                type: GET_FEEDBACK_REQUEST_BY_ID,
                payload: []
            })
        })
}

export const reassignUserFeedbackRequest = (data) => dispatch => {
    dispatch({ type: LOADING_FORMS })
    axios.post("/api/admin/reassign-request-to-user", data)
        .then(res => {
            dispatch({
                type: GET_FEEDBACK_REQUEST_BY_ID,
                payload: res.data
            })
        })
        .catch(err => {
            console.log(err)
            dispatch({
                type: GET_FEEDBACK_REQUEST_BY_ID,
                payload: []
            })
        })
}

