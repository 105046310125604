import React from "react"
import axios from "axios"
import { connect } from 'react-redux'

import Preloader from './common/Preloader'

class AdminToUpdateData extends React.Component {
    constructor() {
        super();
        this.state = {
            message: '',
            usersWithUpdatedEmail: [],
        }

    }

    componentDidUpdate(prevProps) {
        if(this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
            this.props.history.push('/login')
        }
    }

    componentDidMount() {
        if(!this.props.auth.isAuthenticated) {
            this.props.history.push('/login')
        }

        axios.get('/api/admin/update-users-email-to-lowercase')
            .then(res => {
                this.setState({
                    message: res.data.message,
                    usersWithUpdatedEmail: res.data.usersWithUpdatedEmail
                })
            })
            .catch(err => {
                this.setState({
                    message: err.response && err.response.data && err.response.data.message
                })
            })
    }

    render () {
        const { message, usersWithUpdatedEmail } = this.state
        const { auth } = this.props

        return (<>
            <div className="container personal">
                <div className="row">
                    <div className="col s6">
                    <h2><small>Admin Page.</small> <br/>
                        Hello {auth.user.name}</h2>
                    </div>
                </div>
                {message
                 ? <div className="row">
                    <div className="col s6">
                        {message}
                    </div>
                    {usersWithUpdatedEmail.length > 0 &&
                        <div className="row">
                            <div className="col s6">
                                <br/>
                                <h5>Please, save these list of users in some document. We need to check if they have some duplicates and if yes then we have to attach feedbacks to active one.</h5>
                                <ul>
                                    {usersWithUpdatedEmail.map(user => <li key={user.email}>{user.name} - {user.email}</li>)}
                                </ul>
                            </div>
                        </div>
                    }
                </div>
                : <Preloader />}
            </div>
        </>);
    }

}

const mapStateToProps = state => ({
    auth: state.auth
})


export default connect(mapStateToProps, {})(AdminToUpdateData)